<template>
  <div class="w-full ml-auto mr-auto flexCol" style="height:100%; width:95vw; ">
    <div class="w-full  font-bold pb-2 text-black pb-2" style="height:5%;">  Série & couleur extérieure </div>
    <div class="border border-gray-200 mb-2"></div>

    <div class="md:w-1/2 w-full flexCol ml-auto mr-auto flex" style="height:auto;overflow-y: auto">
      <div class="flexCol  w-full h-auto pl-2">
        <h3 class="text-gray-500 text-center p-2 font-bold font-medium"> Peinture extérieure</h3>
        <div class="w-full rounded-md p-2 border border-gray-200" style="display: grid;shadow: 1px 1px 1px rgb(3,0,0); grid-template-columns: repeat(auto-fill,55px);grid-template-rows: repeat(auto-fill,40px);grid-gap:5px;height:auto;">
          <div v-if="color.external"
               :class="color.reference"
               class=" hover:border-black border color  rounded cursor-pointer relative rounded-md"
               v-for="color in $store.state.colors.items"
               :value="color.id"
               :key="color.id"
               style="background-size: cover; width:75px; height:45px;shadow: 2px 2px 2px rgb(3,0,0);"
               @click="selected2(  color.reference, 2, color)">
            <Car class="metal rounded " style="width:75px; height:45px; " :fill="'#' + color.reference"></Car>
          </div>
        </div>
        <!-- <div class="w-full pt-2" style="height:max-content">
          <div v-for="type in $store.state.design.items" @click="selected(type.id, 1, type)"
               class=" prPoint border cursor-pointer text-black hover:bg-white hover:text-black transition border-white flexCol text-sm rounded-sm w-full p-1 m-1">
            <div class="flexRow mr-3"><div class="bg-white ml-2 mr-3 border-black border  z-10 relative" style="word-wrap: break-word;border-radius:50%;height:20px!important;width:20px!important;">
              <div :class="type.id"  class="point h-3 hover:bg-black w-3 border-white  border-3 bg-white" style="border-radius:50%;margin: 3px 3px 3px 3px;"></div>
            </div >
              {{ type.equipment }}
            </div>
            <div class="w-3/4 h-1 mt-1  border-b border-gray-300">
            </div>
          </div>
        </div> -->

      </div>
      <div class="flexCol w-full pl-2">
        <h3 class=" text-center text-gray-500 p-2 font-bold font-medium"> Couleur des tissus intérieur</h3>
        <div class="w-full h-auto">
          <div class="w-full rounded-md p-2 border border-gray-200" style="display: grid;grid-template-columns: repeat(auto-fill,75px);grid-template-rows: repeat(auto-fill,45px);grid-gap:2px;height:auto;">
            <div  :class="color.reference + 'h'" class="  texture color2 text-black h-10 w-10 relative hover:border-black border border-gray-400 rounded cursor-pointer"
                  v-for="color in $store.state.colors.items"
                  :value="color.id"
                  style="background-size: cover; width:75px; height:45px;"
                  @click="selected4(  color.reference , 4, color)">
                  <Seat class="metal rounded " style="width:75px; height:45px; " :fill="'#' + color.reference"></Seat>

            </div>
          </div>
          <!-- <div v-for="type in $store.state.interieur.fabrics" @click="selected3(type.id, 3, type)"
               class=" prPoint border cursor-pointer text-black hover:bg-white hover:text-black transition border-white flexCol text-sm rounded-sm w-full p-1 m-2">
            <div class="flexRow mr-3">
              <div class="bg-white ml-2 mr-3 border-black border h-5 w-5 z-10 relative" style="border-radius:50%;">
                <div :class="type.id + '2'"  class="point3 h-3 hover:bg-black w-3 border-white  border-3 bg-white z-20" style="border-radius:50%;margin:3px 3px 3px 3px;">
                </div></div >{{ type.equipment }} </div> <div class="w-full h-1 mt-1  border-b border-gray-300"></div>
          </div> -->
        </div>
      </div>

    </div>
    <button class="button1 ml-auto mr-auto w-1/3 mt-5" style="vertical-align:middle; " @click="check"><span>Valider </span></button>

  </div>
</template>

<script>
import jQuery from "jquery";
import Car from '@/assets/images/Car.vue'
import Seat from '@/assets/images/Seat.vue'
const $ = jQuery
export default {
  components: {Car, Seat},
  name: "Step3to5.vue",
  mounted(){
    this.$store.state.stept = 3
    $('.progress').animate({
        width: `30%`
      }, 200)
  },
  methods: {
    check() {
      this.$store.state.errors = [];
      console.log(this.$store.state.errors.length)

      if (!this.$store.state.colorInt.reference) {
        console.log('null')
        this.$store.state.errors.push('Veuillez renseigner la couleur dominante à intérieur')
      }
        if (!this.$store.state.colorXt.reference) {
          console.log('test')
          this.$store.state.errors.push('Veuillez renseigner la couleur de la carrosserie')
        }
        if (this.$store.state.errors.length < 1) {
          console.log('No error')
          this.nextStep()
        } else {
          console.log('next2')
          let string = ''
          this.$store.state.errors.forEach((e) => {
            string += e + ',' + ' '
          })
          this.showError('Champs manquants', string)
        }

  },
    nextStep() {
console.log(this.$store.state.trademodel)
      this.$store.state.sharePoint.step = 5.5
      this.$store.state.stept= 5.5
      if (this.$store.state.maxStept < this.$store.state.stept) this.$store.state.maxStept = 5
      if(this.$store.state.interieur.fabrics[0]) {
        this.$store.state.typeInt = this.$store.state.interieur.fabrics[0]
        console.log(this.$store.state.typeInt)
      }
      if(this.$store.state.design.items[0]) {
        this.$store.state.typeXt = this.$store.state.design.items[0]
        console.log(this.$store.state.typeXt)
      }
    },
/*     selected (id, field, typeXt) {
      this.$store.state.option1=true
      $('.point').removeClass('black')
      $('.' + id).addClass('black')
      // this.$store.state.typeXt = typeXt save !!


      if (this.$store.state.option1 === true && this.$store.state.option2 === true && this.$store.state.option3 === true && this.$store.state.option4 === true) {
        this.$store.state.stept = 5.5
        this.$store.state.sharePoint.step = 5.5
        if (this.$store.state.maxStept < this.$store.state.stept) this.$store.state.maxStept = 5.5
        !(this.$store.state.option1 === this.$store.state.option2 === this.$store.state.option3 === this.$store.state.option4)
      }
    }, */

    selected2 (id, field, colorXt) {
      this.$store.state.option2 = true
      $('.color').removeClass('blackCircle')
      $('.' + id).addClass('blackCircle')
      this.$store.state.colorXt = colorXt
      if (this.$store.state.option1 === true && this.$store.state.option2 === true && this.$store.state.option3 === true && this.$store.state.option4 === true) {
        this.$store.state.stept = 4
        this.$store.state.sharePoint.step = 4
        if (this.$store.state.maxStept < this.$store.state.stept) this.$store.state.maxStept = 4

        !(this.$store.state.option1 === this.$store.state.option2 === this.$store.state.option3 === this.$store.state.option4)
      }
    },

   /*  selected3 (id, field, typeInt) {
      this.$store.state.option3=true
      $('.point3').removeClass('black')
      $('.' + id + '2').addClass('black')
      // this.$store.state.typeInt = typeInt save

      if (this.$store.state.option1 === true && this.$store.state.option2 === true && this.$store.state.option3 === true && this.$store.state.option4 === true) {
        this.$store.state.stept = 5.5
        this.$store.state.sharePoint.step = 5.5
        if (this.$store.state.maxStept < this.$store.state.stept) this.$store.state.maxStept = 5.5
        !(this.$store.state.option1 === this.$store.state.option2 === this.$store.state.option3 === this.$store.state.option4)
      }
    }, */
    selected4 (id, field, colorInt) {
      this.$store.state.option4=true
      $('.color2').removeClass('blackCircle')
      $('.' + id + 'h').addClass('blackCircle')
      this.$store.state.colorInt = colorInt
      if (this.$store.state.option1 === true && this.$store.state.option2 === true && this.$store.state.option3 === true && this.$store.state.option4 === true) {
        this.$store.state.stept = 4
        this.$store.state.sharePoint.step = 4
        if (this.$store.state.maxStept < this.$store.state.stept) this.$store.state.maxStept = 4
        !(this.$store.state.option1 === this.$store.state.option2 === this.$store.state.option3 === this.$store.state.option4)
      }
    },
    showError(title, msg) {
      this.$store.state.errorMsg = "Erreur détéctée"
      this.$store.state.errorDescription = ''

      $('#errorModalId').animate({
        top: "300px"
      },1000, 'linear', () => {
        this.$store.state.errorMsg = title
        this.$store.state.errorDescription = msg
      })
    },
    closeError() {
      $('#errorModalId').animate({
        top: "-600px"
      },1000, 'linear', () => {
        this.$store.state.errorMsg = ''
        this.$store.state.errorDescription = ''
      })
    },
  }
}
</script>

<style scoped>

</style>