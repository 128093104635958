<template>
  <div id="Step9" class="relative w-full">
    <div class="step9 flexRow relative overflow-y-auto overflow-x-hidden ml-auto mr-auto"
      style="min-height:65vh;height:auto;>background: linear-gradient(180deg, rgba(255,255,255,0.82) 0%, rgba(214,221,222,1) 100%);">
      <div class="w-full">

        <div id="errorDiv"></div>

        <form class="md:w-1/2 w-full mx-auto" action="" style="" v-if="$store.state.estimation">
          <div class="flex flex-wrap w-full">
            <div class=" w-full md:w-full flexCol mx-auto">

              <div class="flexRow pl-2 md:w-1/3 w-full mx-auto">
                <!--              <label class="w-full text-left pl-2 " for="civil">Civilité</label>-->

                <label class="inline-flex items-center block text-gray-500 font-bold md:text-right mb-1 md:mb-0"
                  for="firstUserDontKnow">
                  <input v-model="$store.state.trademodel.customer.civility" type="radio" class="form-radio" id="civilMr"
                    name="civility" value='2' />
                  <span class="ml-2 text-left">Monsieur</span>
                </label>
                <label class="inline-flex items-center block text-gray-500 font-bold md:text-right mb-1 md:mb-0"
                  for="firstUserDontKnow">
                  <input v-model="$store.state.trademodel.customer.civility" type="radio" class="form-radio" id="civilMs"
                    name="civility" value='1' />
                  <span class="ml-2 text-left">Madame</span>
                </label>
                <div class="border border-gray-200 mb-5"></div>

              </div>

              <div class="flexCol w-11/12">
                <div class="md:flex md:items-center  mb-0">
                  <div class="md:w-1/4">
                    <label class="block text-gray-500 text-xs font-bold md:text-left mb-1 md:mb-0 pr-4"
                      for="inline-full-name">
                      Nom
                    </label>
                  </div>
                  <div class="md:w-full">
                    <input v-model="$store.state.trademodel.customer.name" name="fname" placeholder="Nom"
                      class=" appearance-none border border-gray-200 rounded w-full py-1 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                      id="inline-full-name" type="text">
                  </div>
                </div>
              </div>

              <div class="flexCol w-11/12" style="">
                <div class="md:flex md:items-center mb-0">
                  <div class="md:w-1/4">
                    <label class="block text-gray-500 font-bold md:text-left text-xs mb-1 md:mb-0 pr-4"
                      for="inline-full-lastname">
                      Prénom
                    </label>
                  </div>
                  <div class="md:w-full">

                    <input v-model="$store.state.trademodel.customer.firstname" name="lname" placeholder="Prénom"
                      class=" appearance-none border border-gray-200 rounded w-full py-1 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                      id="inline-full-lastname" type="text">
                  </div>
                </div>
              </div>

              <div class="flexCol w-11/12">
                <div class="md:flex md:items-center mb-0">
                  <div class="md:w-1/4">
                    <label class="block text-gray-500 font-bold md:text-left text-xs mb-1 md:mb-0 pr-4"
                      for="inline-full-cp">
                      Code postale
                    </label>
                  </div>
                  <div class="md:w-full">
                    <input type="number" maxlength="5" v-model="zipCode" name="cp" placeholder="Code postal"
                      class=" appearance-none border border-gray-200 rounded w-full py-1 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                      id="inline-full-cp">
                  </div>
                </div>
              </div>

              <div class="flexCol  w-11/12">
                <div class="md:flex md:items-center mb-0">
                  <div class="md:w-1/4">
                    <label class="block text-gray-500 font-bold md:text-left text-xs mb-1 md:mb-0 pr-4"
                      for="inline-full-mail">
                      E-mail
                    </label>
                  </div>
                  <div class="md:w-full">
                    <input v-model="$store.state.trademodel.customer.email" name="email" placeholder="email@web.com"
                      class=" appearance-none border border-gray-200 rounded w-full py-1 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                      id="inline-full-mail" type="email">
                  </div>
                </div>
              </div>

              <div class="flexCol  w-11/12">
                <div class="md:flex md:items-center mb-6">
                  <div class="md:w-1/4">
                    <label class="block text-gray-500 font-bold md:text-left text-xs mb-1 md:mb-0 pr-4"
                      for="inline-full-phone">
                      N° mobile
                    </label>
                  </div>
                  <select name="" id="" v-model="indicatif" class="py-1 mb-3 rounded p-1">
                    <option value="+33">France</option>
                    <option value="+32">Belgique</option>
                    <option value="+41">Suisse</option>
                  </select>
                  <div class="md:w-full" style="height:50px;">
                    <input type="number" maxlength="10" v-model="cellphone" name="phone" placeholder="0601020304"
                      class=" appearance-none border border-gray-200 rounded w-full py-1 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                      id="inline-full-phone">

                  </div>
                </div>
              </div>

            </div>

          </div>

          <p v-if="$store.state.estimation && !$store.state.validationSms" class="ml-1 md:mx-auto mr-auto mt-3 md:w-1/2">
            <input v-model="$store.state.marketingConsent" type="checkbox" id="test6">
            <label for="test6" class="text-black m-2 block w-full text-left pl-2 textSm" aria-describedby="label"><span
                class="ui"></span>Je consens à recevoir des offres sur les produits et services de XCar Estimation par
              email ou sms</label>
          </p>
          <p v-if="$store.state.estimation && !$store.state.validationSms" class="ml-1 md:mx-auto mr-auto mt-3 md:w-1/2">
            <input type="checkbox" v-model="$store.state.thirdConsent" id="test7">
            <label for="test7" class="text-black m-2 block w-full text-left pl-2 textSm" aria-describedby="label"><span
                class="ui"></span>Je consens à ce que mes données personnelles soient transmises à des tiers partenaires
              de XCar Estimation</label>
          </p>

          <div v-if="$store.state.estimation && $store.state.validationSms"
            class=" block button2 ml-auto mr-auto mt-5 w-full md:w-full" @click="getResult" style=" height:fit-content;">
            Recevoir mon estimation</div>
          <div  v-if="$store.state.thirdConsent && $store.state.marketingConsent && $store.state.validationSms === false && !validSent"
            class="mb-12 block bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ml-auto mr-auto mt-5 w-full md:w-full"
            @click="getOtp($store.state.trademodel.customer.cellphone)" style=" height:fit-content; cursor: pointer;">Recevoir mon code
          </div>

          <div v-if="!$store.state.validationSms && validSent"> Vous allez reçevoir un code de validation par Sms :</div>
          <v-otp-input v-if="!$store.state.validationSms && validSent"></v-otp-input>
          <div v-if="!$store.state.validationSms && validSent"> Nombre de tentatives : {{ $store.state.tentatives }}
          </div> <br>
          <div  v-if="validSent && !validSent2" style="text-decoration: dotted; cursor: pointer;" @click="getOtp($store.state.trademodel.customer.cellphone)"> Je n'ai pas recu mon code (valable 1 fois) </div>
        </form>

      </div>
    </div>
  </div>
</template>

<script>

import OtpInput from '../components'
import jQuery from 'jquery'
const $ = jQuery
export default {
  name: 'Step9.vue',
  components: { 'v-otp-input': OtpInput },
  data() {
    return {
      validSent: false,
      validSent2: false,
      indicatif: '+33',
      notMobile: true,
      zipCode: '',
      cellphone: '',
      sec: 60,
      code: '',
      tryGetResult: 0,
      enableRetry: false
    }
  },
  watch: {
    cellphone(e) {
      if (e.length >= 10) {
        this.$store.state.trademodel.customer.cellphone = e.slice(1, 10)
      }
    },
    zipCode(e) {
      const test = /\d{2}[ ]?\d{3}/ // regex
      if (e.match(test)) {
        this.$store.state.trademodel.customer.zipCode = e
        const cp = $('#inline-full-cp')
        cp.removeClass('notValid')
        cp.addClass('valid')
      } else {
        if (e.length > 4) {
          this.showError('Code postal invalide', 'Veuillez renseigner un code postal valide ...')
          $('#inline-full-cp').addClass('notValid')
        } else {
          $('#inline-full-cp').addClass('notValid')
        }
      }
    }
  },
  methods: {
    checkCodeTo10() {
      if (this.code === this.$store.state.smsCode) {
        console.log('Code is Valid')
        this.getResult()
      } else {
        this.showError('Code invalide', 'Le code que vous avez renseigné est invalide ou périmé. Veuillez re-essayer')
      }
    },
    getResult() {
      event.preventDefault()
      this.$store.state.loading = true
      console.log(this.$store.state.trademodel.car.estimatedDamages)
      const timeElapsed = Date.now()
      const today = new Date(timeElapsed)

      this.$store.state.trademodel.car.configuration = 3
      this.$store.state.trademodel.car.typeId = this.$store.state.catalog.typeId
      // this.$store.state.trademodel.car.carId = this.referentialCarId
      this.$store.state.trademodel.car.carId = parseInt(this.$store.state.referentialCarId, 10)
      this.$store.state.trademodel.car.referentialCarId = this.$store.state.referentialCarId
      this.$store.state.trademodel.car.type = this.$store.state.catalog.type
      this.$store.state.trademodel.car.make = this.$store.state.catalog.make
      this.$store.state.trademodel.car.makeId = this.$store.state.catalog.makeId
      this.$store.state.trademodel.car.modelGroupId = this.$store.state.catalog.modelGroupId
      this.$store.state.trademodel.car.modelGroup = this.$store.state.catalog.modelGroup
      this.$store.state.trademodel.car.generation = this.$store.state.catalog.generation
      this.$store.state.trademodel.car.modelId = this.$store.state.catalog.modelId
      this.$store.state.trademodel.car.model = this.$store.state.catalog.model
      this.$store.state.trademodel.car.trim = this.$store.state.catalog.trimline
      this.$store.state.trademodel.car.version = this.$store.state.catalog.version
      this.$store.state.trademodel.car.fuelId = this.$store.state.catalog.fuelId
      this.$store.state.trademodel.car.fuel = this.$store.state.catalog.fuel
      this.$store.state.trademodel.car.genericFuelId = this.$store.state.catalog.genericFuelId
      this.$store.state.trademodel.car.genericFuel = this.$store.state.catalog.fuel
      this.$store.state.trademodel.car.transmissionTypeId = this.$store.state.catalog.transmissionTypeId
      this.$store.state.trademodel.car.transmissionType = this.$store.state.catalog.transmissionType
      this.$store.state.trademodel.car.genericTransmissionTypeId = this.$store.state.catalog.genericTransmissionTypeId
      this.$store.state.trademodel.car.genericTransmissionType = this.$store.state.catalog.genericTransmissionType
      this.$store.state.trademodel.car.bodyId = this.$store.state.catalog.bodyId
      this.$store.state.trademodel.car.body = this.$store.state.catalog.body
      this.$store.state.trademodel.car.genericBodyId = this.$store.state.catalog.genericBodyId
      this.$store.state.trademodel.car.genericBody = this.$store.state.catalog.genericBody
      this.$store.state.trademodel.car.segmentationId = this.$store.state.catalog.segmentationId
      this.$store.state.trademodel.car.segmentation = this.$store.state.catalog.segmentation
      this.$store.state.trademodel.car.driveTypeId = this.$store.state.catalog.driveTypeId
      this.$store.state.trademodel.car.driveType = this.$store.state.catalog.driveType
      this.$store.state.trademodel.car.genericDriveTypeId = this.$store.state.catalog.genericDriveTypeId
      this.$store.state.trademodel.car.genericDriveType = this.$store.state.catalog.genericDriveType
      this.$store.state.trademodel.car.emissionId = this.$store.state.catalog.emissionId
      this.$store.state.trademodel.car.emission = this.$store.state.catalog.emission
      this.$store.state.trademodel.car.seats = this.$store.state.catalog.seats
      if (this.$store.state.imported === 'true') {
        this.$store.state.trademodel.car.import = true
      } else {
        this.$store.state.trademodel.car.import = false
      }
      this.firstHand ? this.$store.state.trademodel.car.firstHand = true : this.$store.state.trademodel.car.firstHand = false
      this.upToDateMaintenanceBook ? this.$store.state.trademodel.car.upToDateMaintenanceBook = true : this.$store.state.trademodel.car.upToDateMaintenanceBook = false
      this.marketingConsent ? this.$store.state.trademodel.customer.marketingConsent = true : this.$store.state.trademodel.customer.marketingConsent = false
      this.thirdConsent ? this.$store.state.trademodel.customer.thirdConsent = true : this.$store.state.trademodel.customer.thirdConsent = false
      this.$store.state.trademodel.car.gears = this.$store.state.catalog.gears
      this.$store.state.trademodel.car.doors = this.$store.state.catalog.gears
      this.$store.state.trademodel.car.valves = this.$store.state.catalog.valves
      this.$store.state.trademodel.car.torque = this.$store.state.catalog.torque
      this.$store.state.trademodel.car.displacement = this.$store.state.catalog.displacement
      this.$store.state.trademodel.car.cylinders = this.$store.state.catalog.cylinders
      this.$store.state.trademodel.car.cylindersTypeId = this.$store.state.catalog.cylindersTypeId
      this.$store.state.trademodel.car.cylindersType = this.$store.state.catalog.cylindersType
      this.$store.state.trademodel.car.horsepower = this.$store.state.catalog.horsepower
      this.$store.state.trademodel.car.taxHorsepower = this.$store.state.catalog.taxHorsepower
      this.$store.state.trademodel.car.kilowatt = this.$store.state.catalog.kilowatt
      this.$store.state.trademodel.car.co2Emission = this.$store.state.catalog.co2Emission
      this.$store.state.trademodel.car.mixedConsumption = this.$store.state.catalog.mixedConsumption
      this.$store.state.trademodel.car.urbanConsumption = this.$store.state.catalog.urbanConsumption
      this.$store.state.trademodel.car.extraUrbanConsumption = this.$store.state.catalog.extraUrbanConsumption
      this.$store.state.trademodel.car.length = this.$store.state.catalog.length
      this.$store.state.trademodel.car.width = this.$store.state.catalog.width
      this.$store.state.trademodel.car.height = this.$store.state.catalog.height
      this.$store.state.trademodel.car.totalWeight = this.$store.state.catalog.totalWeight
      this.$store.state.trademodel.car.payload = this.$store.state.catalog.payload
      this.$store.state.trademodel.car.rooflad = this.$store.state.catalog.rooflad
      this.$store.state.trademodel.car.curbWeight = this.$store.state.catalog.curbWeight
      this.$store.state.trademodel.car.topSpeed = this.$store.state.catalog.topSpeed
      this.$store.state.trademodel.car.acceleration = this.$store.state.catalog.acceleration
      this.$store.state.trademodel.car.trunkMinimumCapacity = this.$store.state.catalog.trunkMinimumCapacity
      this.$store.state.trademodel.car.trunkMaximumCapacity = this.$store.state.catalog.trunkMaximumCapacity
      this.$store.state.trademodel.car.modelCatalogBegin = this.$store.state.catalog.modelCatalogBegin
      this.$store.state.trademodel.car.modelCatalogEnd = this.$store.state.catalog.modelCatalogEnd
      this.$store.state.trademodel.car.catalogBeginPrice = this.$store.state.catalog.catalogPriceBegin
      this.$store.state.trademodel.car.catalogEndPrice = this.$store.state.catalog.catalogPriceEnd
      this.$store.state.trademodel.car.catalogExclVatPrice = this.$store.state.catalog.catalogExclVatPrice
      this.$store.state.trademodel.car.catalogInclVatPrice = this.$store.state.catalog.catalogInclVatPrice
      this.$store.state.trademodel.car.catalogTax = this.$store.state.catalog.catalogTax
      // this.$store.state.trademodel.car.catalogPriceOverloaded
      this.$store.state.trademodel.car.frontTyreWidth = this.$store.state.catalog.frontTyreWidth
      this.$store.state.trademodel.car.frontTyreHeight = this.$store.state.catalog.frontTyreHeight
      this.$store.state.trademodel.car.frontTyreSpeedIndex = this.$store.state.catalog.frontTyreSpeedIndex
      this.$store.state.trademodel.car.frontTyreDiameter = this.$store.state.catalog.frontTyreDiameter
      // this.$store.state.trademodel.car.frontTyreWear
      this.$store.state.trademodel.car.mileage = parseInt(this.$store.state.mileage, 10)
      this.$store.state.trademodel.car.mileageDate = today.toISOString()
      this.$store.state.trademodel.car.forecastedDate = today.toISOString()
      this.$store.state.trademodel.car.rearTyreWidth = this.$store.state.catalog.rearTyreWidth
      this.$store.state.trademodel.car.rearTyreHeight = this.$store.state.catalog.rearTyreHeight
      this.$store.state.trademodel.car.rearTyreSpeedIndex = this.$store.state.catalog.rearTyreSpeedIndex
      this.$store.state.trademodel.car.rearTyreDiameter = this.$store.state.catalog.rearTyreDiameter
      // this.$store.state.trademodel.car.rearTyreWear
      this.$store.state.trademodel.car.internalColorCode = this.$store.state.colorInt.code
      this.$store.state.trademodel.car.internalColorWording = this.$store.state.colorInt.wording
      this.$store.state.trademodel.car.internalColorReference = this.$store.state.colorInt.reference
      this.$store.state.trademodel.car.origin = 1
      this.$store.state.trademodel.car.guaranteedMileage = false
      this.$store.state.trademodel.car.externalColorCode = this.$store.state.colorXt.reference
      this.$store.state.trademodel.car.externalColorWording = this.$store.state.colorXt.wording
      this.$store.state.trademodel.car.externalColorReference = this.$store.state.colorXt.reference
      this.$store.state.trademodel.car.forecastedMileage = parseInt(this.$store.state.trademodel.car.mileage, 10)
      this.$store.state.trademodel.car.numberplate = this.$store.state.imatSearchInput
      this.$store.state.trademodel.car.tcenum = this.$store.state.catalog.tcenum
      this.$store.state.trademodel.car.codeFactory = this.$store.state.catalog.codeFactory
      this.$store.state.trademodel.car.referentialPicture = this.$store.state.catalog.picture
      this.$store.state.trademodel.car.applyMaintenanceCosts = true
      this.$store.state.trademodel.car.pictures = []
      this.$store.state.trademodel.car.referentialPollutionClassification = this.$store.state.catalog.pollutionClassification
      // this.$store.state.trademodel.car.saddleries = [{id: parseInt(this.colorInt.id), code: this.colorInt.code, equipment: this.colorInt.equipment, inclVatPrice: this.colorInt.inclVatPrice, exclVatPrice: this.colorInt.exclVatPrice, tax: this.colorInt.tax, serial: this.colorInt.serial, manufacturer: this.colorInt.manufacturer, label: this.colorInt.equipment, price: this.colorInt.inclVatPrice, referentialId: this.colorInt.id, wording: this.typeXt.wording }]
      // this.$store.state.trademodel.car.paintings = [{id: parseInt(this.colorXt.id), code: this.colorXt.code, equipment: this.colorXt.equipment, inclVatPrice: this.colorXt.inclVatPrice, exclVatPrice: this.colorXt.exclVatPrice, tax: this.colorXt.tax, serial: this.colorXt.serial, manufacturer: this.colorXt.manufacturer, label: this.colorXt.equipment, price: this.colorXt.inclVatPrice, referentialId: this.colorXt.id, wording: this.typeXt.wording }]
      // this.$store.state.trademodel.car.paintings = { ...this.colorXt, ...this.typeXt }
      this.$store.state.trademodel.car.paintings = [{ id: parseInt(this.$store.state.typeXt.id), code: this.$store.state.typeXt.code, equipment: this.$store.state.typeXt.equipment, inclVatPrice: this.$store.state.typeXt.inclVatPrice, exclVatPrice: this.$store.state.typeXt.exclVatPrice, tax: this.$store.state.typeXt.tax, serial: this.$store.state.typeXt.serial, manufacturer: this.$store.state.typeXt.manufacturer, label: this.$store.state.typeXt.equipment, price: this.$store.state.typeXt.inclVatPrice, referentialId: this.$store.state.typeXt.id, wording: this.$store.state.colorXt.wording }]
      this.$store.state.trademodel.car.saddleries = [{ id: parseInt(this.$store.state.typeInt.id), code: this.$store.state.typeInt.code, equipment: this.$store.state.typeInt.equipment, inclVatPrice: this.$store.state.typeInt.inclVatPrice, exclVatPrice: this.$store.state.typeInt.exclVatPrice, tax: this.$store.state.typeInt.tax, serial: this.$store.state.typeInt.serial, manufacturer: this.$store.state.typeInt.manufacturer, label: this.$store.state.typeInt.equipment, price: this.$store.state.typeInt.inclVatPrice, referentialId: this.$store.state.typeInt.id, wording: this.$store.state.colorInt.wording }]
      // this.$store.state.trademodel.car.saddleries.id = parseInt(this.typeInt.id)
      // this.$store.state.trademodel.car.paintings.id = parseInt(this.typeXt.id)

      this.$store.state.equipementList.forEach((equipment) => {
        this.$store.state.trademodel.car.equipments.push({ packElements: [], equipmentGroupings: [{ group: 'null', groupId: equipment.equipmentGroupings[0].groupId, subgroup: 'null', subgroupId: equipment.equipmentGroupings[0].subgroupId, code: equipment.equipmentGroupings[0].code }], wording: equipment.wording, code: equipment.code, exclVatPrice: equipment.exclVatPrice, inclVatPrice: equipment.inclVatPrice, tax: equipment.tax, referencialId: equipment.referencialId, serial: equipment.serial, pack: equipment.pack, custom: equipment.custom })
      })
      console.log(this.$store.state.defectArray)
      console.log(this.$store.state.trademodel.car.estimatedDamages)
      this.$store.state.trademodel.car.estimatedDamages = this.$store.state.defectArray
      console.log(this.$store.state.trademodel.car.estimatedDamages[0])

      this.$store.state.trademodel.car.estimatedDamages.push(this.$store.state.frontTyreWear)
      this.$store.state.trademodel.car.estimatedDamages.push(this.$store.state.rearTyreWear)
      const tax = { tax: 20, type: 1 }
      this.$store.state.trademodel.car.estimatedDamages.push(tax)
      if (this.$store.state.doubleKey === true) {
        const key = { tax: 20, type: 4, privateExclVatPrice: 200, privateInclVatPrice: 200, professionalExclVatPrice: 200, professionalIncVatPrice: 200, wording: 'Pas de double des clé' }
        this.$store.state.trademodel.car.estimatedDamages.push(key)
      }
      if (this.$store.state.malusImport === false) {
        const key2 = { tax: 20, type: 4, privateExclVatPrice: 200, privateInclVatPrice: 200, professionalExclVatPrice: 200, professionalInclVatPrice: 200, wording: 'Malus taxe importation' }
        this.$store.state.trademodel.car.estimatedDamages.push(key2)
      }
      if (this.$store.state.peintureG === false) {
        const key3 = { tax: 20, type: 4, privateExclVatPrice: 500, privateInclVatPrice: 500, professionalExclVatPrice: 500, professionalInclVatPrice: 500, wording: 'Peinture en mauvaise état' }
        this.$store.state.trademodel.car.estimatedDamages.push(key3)
      }
      if (this.$store.state.sellerieG === false) {
        const key4 = { tax: 20, type: 4, privateExclVatPrice: 400, privateInclVatPrice: 400, professionalInclVatPrice: 400, professionalExclVatPrice: 400, wording: 'Sellerie en mauvais état' }
        this.$store.state.trademodel.car.estimatedDamages.push(key4)
      }
      console.log(this.$store.state.trademodel.car.estimatedDamages)

      this.$store.state.trademodel.car.estimatedDamages.forEach((e, index) => {
        if (e === 0) {
          this.$store.state.trademodel.car.estimatedDamages.splice(index, 1)
        }
      })
      console.log(this.$store.state.trademodel.car.estimatedDamages)

      const config = {
        headers: {
          Authorization: 'Bearer ' + this.$store.state.token
        }
      }
      const datas = this.$store.state.trademodel
      this.axios.post('https://gateway-api.openflex.eu/opportunities/trade-ins', datas, config).then((json) => {
        const campagnId = json.data.id
        const offerId = json.data.offerId
        const opportunityId = json.data.opportunityId
        this.$store.state.sellingPrice = json.data.price
        json.data.price ? this.$store.state.sellingStatus = 2 : this.$store.state.sellingStatus = 1
        if (this.$store.state.maxStept < this.$store.state.stept) this.$store.state.maxStept = 10
        this.$store.state.loading = false
        console.log(this.$store.state.sellingStatus)
        if (this.$store.state.sellingStatus === 1) {
          this.$store.state.estimColor = 'bg-yellow-500'
        } else {
          this.$store.state.estimColor = 'bg-green-400'
        }
        this.$store.state.stept = 10

      }).catch((error) => {
        if (error && this.tryGetResult < 3) {
          this.tryGetResult++
          this.getResult()
        } else {
          setTimeout(() => {
            this.$store.state.loading = false
          }, 3000)
        }
        console.log(error)
      })
    },
    getValidation(id, offerId, opportunityId) {
      const config = {
        headers: {
          Authorization: 'Bearer ' + this.$store.state.token
        }
      }
      this.axios.get(`https://selling-api.openflex.eu/opportunities/${opportunityId}/offers/${offerId}/trade-ins/${id}`, config).then((json) => {
        this.$store.state.sellingStatus = json.data.tradeIn.status
        this.$store.state.sellingPrice = json.data.tradeIn.price
        this.$store.state.stept = 10
        if (this.$store.state.maxStept < this.$store.state.stept) this.$store.state.maxStept = 10
        this.$store.state.loading = false
        console.log(this.$store.state.sellingStatus)
        if (this.$store.state.sellingStatus === 1) {
          this.$store.state.estimColor = 'bg-yellow-500'
        } else {
          this.$store.state.estimColor = 'bg-green-400'
        }
      }).catch((error) => {
        this.$store.state.loading = false
        console.log(error)
      })
    },
    showError(title, msg) {
      this.$store.state.errorMsg = 'Erreur détéctée'
      this.$store.state.errorDescription = ''

      $('#errorModalId').animate({
        top: '300px'
      }, 1000, 'linear', () => {
        this.$store.state.errorMsg = title
        this.$store.state.errorDescription = msg
      })
    },
    closeError() {
      $('#errorModalId').animate({
        top: '-600px'
      }, 1000, 'linear', () => {
        this.$store.state.errorMsg = ''
        this.$store.state.errorDescription = ''
      })
    },
    async getOtp (phone) {
      this.error = []
      if (this.cellphone.slice(0, 1) !== '0' && this.indicatif === '+33') {
        this.error.push('Votre numéro doit commencer par un 0' + this.$store.state.trademodel.customer.cellphone )
      }
      if (!this.$store.state.trademodel.customer.name) this.error.push('Nom')
      if (!this.$store.state.trademodel.customer.firstname) this.error.push('Prenom')
      if (!this.$store.state.trademodel.customer.email) this.error.push('Nom')
      if (!this.$store.state.trademodel.customer.cellphone) this.error.push('Téléphone')
      if (!this.zipCode) this.error.push('Code postal')
      if (this.error.length > 0) {
        console.log(this.error)
        const string = this.error.join(' ')
        alert('Champs manquants : ' + string)
      } else {
        this.$store.state.userData = {
          phone_number: this.indicatif + phone,
          first_name: this.$store.state.trademodel.customer.name,
          last_name: this.$store.state.trademodel.customer.firstname,
          email: this.$store.state.trademodel.customer.email,
          cp: this.zipCode
        }
        if (this.validSent === true ) {this.validSent2 = true} else {this.validSent = true}
        
        const getOtpReq = await this.axios.get('https://otp.backend.xcar-estimation.fr/config/getotp/' + this.indicatif + phone)
        console.log(getOtpReq.data.data.otp_request_token)
        this.$store.state.otpToken = getOtpReq.data.data.otp_request_token
      }
    },

    handleOnComplete(value) {
      console.log('OTP completed: ', value)
      this.$store.state.otp = value
      if (this.$store.state.otp === this.$store.state.smsCode) {
        console.log('Code is Valid')
        this.getResult()
      } else {
        this.showError('Code invalide', 'Le code que vous avez renseigné est invalide ou périmé. Veuillez re-essayer')
      }
    },
    handleOnChange(value) {
      if (this.$store.state.otp === this.$store.state.smsCode) console.log('Success')
    },

    checkCode() {
      if (this.$store.state.essaiSms > 0) {
        this.$store.state.essaiSms = this.$store.state.essaiSms - 1
        this.$store.state.von()
      }
    },
    randomIntFromInterval(min, max) { // min and max included
      return Math.floor(Math.random() * (max - min + 1) + min)
    },
    goTimer(time) {
      this.sec = time
      setInterval((i) => {
        this.sec--
        if (this.sec < 1) {
          this.enableRetry = true
          clearInterval(this)
        } else {
          this.enableRetry = false
        }
      }, 1000)
    },
    von() {
      if (window.matchMedia('(min-width: 500px)').matches) {
        this.notMobile = true
      } else {
        this.notMobile = false
      }

      this.$store.state.essaiSms--
      event.preventDefault()
      this.goTimer(60)
      this.$store.state.errors = []
      const phone = $('#inline-full-phone')
      const name = $('#inline-full-name')
      const lname = $('#inline-full-lastname')
      const cp = $('#inline-full-cp')
      const mail = $('#inline-full-mail')
      if (!this.$store.state.trademodel.customer.cellphone) {
        this.$store.state.errors.push('Numéros de telephone requis.')

        phone.addClass('error')
        phone.removeClass('valid')
      } else {
        phone.addClass('valid')
        phone.removeClass('error')
      }
      if (!this.$store.state.trademodel.customer.name) {
        this.$store.state.errors.push('Votre nom est requis.')
        name.addClass('error')
        name.removeClass('valid')
      } else {
        name.addClass('valid')
        name.removeClass('error')
      }
      if (!this.$store.state.trademodel.customer.firstname) {
        this.$store.state.errors.push('Votre prénom est requis.')
        lname.addClass('error')
        lname.removeClass('valid')
      } else {
        lname.addClass('valid')
        lname.removeClass('error')
      }
      if (!this.$store.state.trademodel.customer.zipCode) {
        this.$store.state.errors.push('Le code postal est requis')
        cp.addClass('error')
        cp.removeClass('valid')
      } else {
        cp.addClass('valid')
        cp.removeClass('error')
      }
      if (!this.$store.state.trademodel.customer.email) {
        this.$store.state.errors.push('Votre Email est obligatoire.')
        mail.addClass('error')
        mail.removeClass('valid')
      } else {
        mail.addClass('valid')
        mail.removeClass('error')
      }
      /*      if (this.$store.state.trademodel.customer.marketingConsent === false) {
        console.log(this.$store.state.trademodel.customer.marketingConsent)
        this.$store.state.errors.push("Veuillez accepter nos conditions d'utilisation")
      } */
      if (this.$store.state.errors.length < 1) {
        this.$store.state.estimation = false
        this.$store.state.smsCode = this.randomIntFromInterval(1000, 9999).toString()
        this.$store.state.phoneNumber = '+33' + this.$store.state.trademodel.customer.cellphone
        this.$store.state.message = 'Bonjour,' + this.$store.state.trademodel.customer.name
        this.$store.state.message += ' ' + this.$store.state.trademodel.customer.firstname
        this.$store.state.message += ".Votre code d'authentification sur Xcar Estimation est le " + this.$store.state.smsCode

        const SERVICE_PLAN_ID = process.env.VUE_APP_SERVICE_PLAN_ID
        const API_TOKEN = process.env.VUE_APP_API_TOKEN
        const SINCH_NUMBER = process.env.VUE_APP_SINCH_NUMBER

        const sendTransacSms = {
          sender: 'Xcar',
          recipient: this.$store.state.phoneNumber,
          content: this.$store.state.message
        }
        fetch('https://api.sendinblue.com/v3/transactionalSMS/sms', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Api-Key': API_TOKEN,
            'Access-Control-Allow-Origin': 'https://backend.xcar-estimation.fr',
            'X-Requested-With': 'XMLHttpRequest'
          },
          body: JSON.stringify(sendTransacSms)
        }).catch((error) => {
          this.$store.state.loading = false
          console.log(error)
        })
      } else {
        let string = ''
        this.$store.state.errors.forEach((e) => {
          string += e + ',' + ' '
        })
        this.showError('Champs manquants', string)
      }
    }
  }

}
</script>

<style scoped>input {
  margin: 2px 10px !important;
}</style>
