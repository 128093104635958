<template>
  <div id="Step1" class="md:w-1/2 w-full ml-auto mr-auto  rounded" style="height:100%;">
    <div class="w-full text-black  border-gray-300 font-bold pb-1"> Quelle est la version de votre véhicule ? </div>
    <div class="border border-gray-200 ml-auto mr-auto mb-2"></div>

    <div :id="model.referentialCarId" v-for="(model, index) in $store.state.imatData" :key="index" class="rounded w-full pl-2 cursor-pointer  pr-2" @click="getCatalog(model.referentialCarId, 2, model.catalog, model )">
        <div class="text-left w-full pl-2 relative  flexRow textMd hover:border-red-500 border-2 mb-2 rounded transition" style="height:auto;">
          <div class="md:w-3/5 w-full flex items-center" style="height:auto;">{{ model.version }} {{ model.trimline}}</div>
          <div class="flexCol w-1/6">
          <div class="door ml-auto mr-auto"></div>
          <div style="" class="font-bold mr-2 textSm text-center"> {{ model.doors }}</div>
          </div>
          <div class="flexCol w-1/6 ">
          <svg width="20px" viewBox="0 0 17.4 17.4" class="ml-auto mr-auto mt-auto sc-iBzEeX glZmJR sc-dTSzeu krkYvd"><path d="M6.5 8H5.1v1.5h1.4V8zm2.9 0H8v1.5h1.4V8zm2.9 0h-1.4v1.5h1.4V8zm1.5-5.1H13V1.4h-1.5v1.4H5.8V1.4H4.3v1.4h-.7c-.8 0-1.4.6-1.4 1.4v10.1c0 .8.6 1.4 1.4 1.4h10.1c.8 0 1.4-.6 1.4-1.4v-10c.1-.8-.5-1.4-1.3-1.4zm0 11.6H3.6v-8h10.1l.1 8z"></path></svg>
          <div class="text-red-700 font-bold  mt-auto ml-auto mr-auto flexRow pb-1 textSm text-center" style=" ">{{ model.catalog.slice(0,4)}} </div>
          </div>
          <div class="absolute right-1 top-4 arrowRedRight flex items-center  ml-auto mr-1 pr-1 w-4"></div>

        </div>
      </div>

  </div>
</template>

<script>
import jQuery from "jquery";
const $ = jQuery
export default {
  name: "Step1.vue",
  data() {
    return {
      model: ''
    }
  },
  created() {
    this.$store.state.sharePoint.step = 2
  },
  mounted(){
    this.$store.state.stept = 1
    $('.progress').animate({
        width: `10%`
      }, 200)
  },  
  methods: {
    getCatalog(id, step, catalog, model) {
      console.log(model)

      this.$store.state.putIntoService = model.putIntoService.slice(0,10)
      console.log(this.$store.state.putIntoService)
      this.$store.state.loading = true
      this.$store.state.modelChoose = model
      this.$store.state.referentialCarId = id
      this.$store.state.stept = step
      this.$store.state.sharePoint.step = step
      if (this.$store.state.maxStept < this.$store.state.stept) this.$store.state.maxStept = 1
      this.$store.commit('getCatalog')
    }
  }
}
</script>

<style scoped>

.tilesWrap {
  padding: 0;
  margin: 50px auto;
  list-style: none;
  text-align: center;
}
.tilesWrap li {
  display: inline-block;
  width: 20%;
  min-width: 200px;
  max-width: 230px;
  padding: 80px 20px 40px;
  position: relative;
  vertical-align: top;
  margin: 10px;
  font-family: 'helvetica', san-serif;
  min-height: 25vh;
  background: #262a2b;
  border: 1px solid #252727;
  text-align: left;
}
.tilesWrap li h2 {
  font-size: 114px;
  margin: 0;
  position: absolute;
  opacity: 0.2;
  top: 50px;
  right: 10px;
  transition: all 0.3s ease-in-out;
}
.tilesWrap li h3 {
  font-size: 20px;
  color: #ffffff;
  margin-bottom: 5px;
}
.tilesWrap li p {
  font-size: 16px;
  line-height: 18px;
  color: #b7b7b7;
  margin-top: 5px;
}
.tilesWrap li button {
  background: transparent;
  border: 1px solid #b7b7b7;
  padding: 10px 20px;
  color: #b7b7b7;
  border-radius: 3px;
  position: relative;
  transition: all 0.3s ease-in-out;
  transform: translateY(-40px);
  opacity: 0;
  cursor: pointer;
  overflow: hidden;
}
.tilesWrap li button:before {
  content: '';
  position: absolute;
  height: 100%;
  width: 120%;
  background: #b7b7b7;
  top: 0;
  opacity: 0;
  left: -140px;
  border-radius: 0 20px 20px 0;
  z-index: -1;
  transition: all 0.3s ease-in-out;

}
.tilesWrap li:hover button {
  transform: translateY(5px);
  opacity: 1;
}
.tilesWrap li button:hover {
  color: #262a2b;
}
.tilesWrap li button:hover:before {
  left: 0;
  opacity: 1;
}
.tilesWrap li:hover h2 {
  top: 0px;
  opacity: 0.6;
}

.tilesWrap li:before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  z-index: -1;
  background: #fff;
  transform: skew(2deg, 2deg);
}
.tilesWrap li:after {
  content: '';
  position: absolute;
  width: 40%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(255, 255, 255, 0.02);
}
.tilesWrap li:nth-child(1):before {
  background: #C9FFBF;
  background: -webkit-linear-gradient(to right, #FFAFBD, #C9FFBF);
  background: linear-gradient(to right, #FFAFBD, #C9FFBF);
}
.tilesWrap li:nth-child(2):before {
  background: #f2709c;
  background: -webkit-linear-gradient(to right, #ff9472, #f2709c);
  background: linear-gradient(to right, #ff9472, #f2709c);
}
.tilesWrap li:nth-child(3):before {
  background: #c21500;
  background: -webkit-linear-gradient(to right, #ffc500, #c21500);
  background: linear-gradient(to right, #ffc500, #c21500);
}
.tilesWrap li:nth-child(4):before {
  background: #FC354C;
  background: -webkit-linear-gradient(to right, #0ABFBC, #FC354C);
  background: linear-gradient(to right, #0ABFBC, #FC354C);
}
</style>