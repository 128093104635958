<template>
  <div style="display: flex; flex-direction: row; justify-content: center; /* Centre horizontalement */
  align-items: center; /* Centre verticalement */" class="mt-3 mb-10">

    <v-otp-input ref="otpInput" input-classes="otp-input" separator="-" :num-inputs="4" :should-auto-focus="true"
      :is-input-num="true" @on-change="handleOnChange" @on-complete="handleOnComplete" />
    <br>
  </div>
</template>

<script>
export default {
  name: 'App',

  methods: {
    async handleOnComplete(value) {
      console.log(this.$store.state.otpToken)
      const req = await this.axios.post('https://otp.backend.xcar-estimation.fr/config/verify',
        {
          "code": value.toString(),
          "token": this.$store.state.otpToken
        })

      console.log(req)
      if (req.data.data.code === 0) {
        this.$store.state.validationSms = true
        console.log(this.$store.state.userData)
        await this.axios.post('http://0.0.0.0:3000/config/addcontact', this.$store.state.userData)

      }
      if (req.data.data.code !== 0) {
        alert('Votre code est inccorect !')
        this.$store.state.tentatives = req.data.data.remaining_number_of_attempts
      }

      if (req.data.data.remaining_number_of_attempts === 0) {
        alert('Vous avez utiliser le nombre maximal de tentative')
      }
    },
    handleOnChange(value) {
      console.log('OTP changed: ', value)
    },
    handleClearInput() {
      this.$nextTick(() => {
        const inputs = this.$refs.otpInputs
        inputs.forEach((input) => {
          input.value = ''
        })
      })
    }
  }
}
</script>

<style lang="css">
.otp-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: 0 10px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;

  &.error {
    border: 1px solid red !important;
  }
}

.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>