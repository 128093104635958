<template>
  <div id="Step7" class="relative ml-auto mr-auto w-full md:w-1/2" style="">

    <div>
      <div class="w-full  text-center text-black font-bold" style="font-size: 20px">Etat des pneus avant</div>
      <img style="width:75vw" src="../assets/styles/pneux.png" class="w-2/3 ml-auto mr-auto rounded" alt="">
      <div class="w-full ml-auto mr-auto justify-between  flexRow  mt-5">
        <button id="av0" style="box-shadow: 6px 5px 7px -2px rgba(0, 133, 47, 0.91)" class="pneuAv rounded ar0U font-semibold hover:text-white border p-2  transition ml-auto mr-auto textSm" @click="etatPneu('av0')" >Parfait état</button>
        <button id="av50" style="box-shadow: 6px 5px 7px -2px rgba(0, 101, 164, 0.85)" class="pneuAv rounded ar50U font-semibold hover:text-white border  p-2  transition ml-auto mr-auto textSm" @click="etatPneu('av50')">Etat correct</button>
        <button id="av100" style="box-shadow: 6px 5px 7px -2px rgba(180,0,0,0.55)" class="pneuAv rounded  ar100U font-semibold hover:text-white border  p-2  transition ml-auto mr-auto textSm" @click="etatPneu('av100')">Mauvais état</button>
      </div>
    </div>
    <div class="mb-5">
      <div class="w-full text-center text-black mt-5 font-bold" style="font-size: 20px">Etat des pneus arrière</div>
      <img style="width:75vw" src="../assets/styles/pneux.png" class="w-2/3 ml-auto mr-auto rounded" alt="">
      <div class="w-full ml-auto mr-auto justify-between  flexRow  mt-5">
        <button id="ar0" style="box-shadow: 6px 5px 7px -2px rgba(0, 133, 47, 0.91)" class="pneuArr rounded ar0U font-semibold hover:text-white border  p-2 rounded transition  ml-auto mr-auto textSm" @click="etatPneu('ar0' )">Parfait état</button>
        <button id="ar50" style="box-shadow: 6px 5px 7px -2px rgba(0, 101, 164, 0.85)" class="pneuArr rounded ar50U font-semibold hover:text-white border  p-2 transition ml-auto mr-auto textSm" @click="etatPneu('ar50')"> Etat correct</button>
        <button id="ar100" style="box-shadow: 6px 5px 7px -2px rgba(180,0,0,0.55)" class="pneuArr rounded  ar100U font-semibold hover:text-white border  p-2 transition  ml-auto mr-auto textSm" @click="etatPneu('ar100')">Mauvais état</button>
      </div>
    </div>
    <button class="button1 ml-auto mr-auto mt-6 w-1/3 md:w-1/6" style="vertical-align:middle; " @click="goDamageSect()"><span>Valider   </span></button>

  </div>
</template>

<script>
import jQuery from "jquery";
const $ = jQuery
export default {
  name: "Step7.vue",
  created() {
    this.etatPneu('ar50')
    this.etatPneu('av50')
  },
  mounted(){
    $('.progress').animate({
        width: `70%`
      }, 200)
  },
  methods: {
    goDamageSect () {
      this.$store.state.stept = 8
      if (this.$store.state.maxStept < this.$store.state.stept) this.$store.state.maxStept = 8
      this.$store.state.loading = true
      this.$store.commit('getData', this.$store.state.getPricePolicies2)
    },
    etatPneu (id) {

      if (id === 'ar0') {
        $('#ar0').addClass('ar0S')
        $('#ar50').removeClass('ar50S')
        $('#ar100').removeClass('ar100S')
        this.$store.state.rearTyreWear = { tax: 20, type: 2, tyreSize: this.$store.state.catalog.rearTyreDiameter, rearTyreWear: 0, privateExclVatPrice: 0,  professionalExclVatPrice: 0}
        this.$store.state.trademodel.car.rearTyreWear = 0
      }
      if (id === 'ar50') {
        $('#ar0').removeClass('ar0S')
        $('#ar50').addClass('ar50S')
        $('#ar100').removeClass('ar100S')
        this.$store.state.rearTyreWear = { tax: 20, type: 2, tyreSize: this.$store.state.catalog.rearTyreDiameter, rearTyreWear: 50 , privateExclVatPrice: 50,  professionalExclVatPrice: 50}
        this.$store.state.trademodel.car.rearTyreWear = 50

      }
      if (id === 'ar100') {
        $('#ar0').removeClass('ar0S')
        $('#ar50').removeClass('ar50S')
        $('#ar100').addClass('ar100S')
        this.$store.state.rearTyreWear = { tax: 20, type: 2, tyreSize: this.$store.state.catalog.rearTyreDiameter, rearTyreWear: 100, privateExclVatPrice: 150,  professionalExclVatPrice: 150 }
        this.$store.state.trademodel.car.rearTyreWear = 100

      }
      if (id === 'av0') {
        $('#av0').addClass('ar0S')
        $('#av50').removeClass('ar50S')
        $('#av100').removeClass('ar100S')
        this.$store.state.frontTyreWear = { tax: 20, type: 2, tyreSize: this.$store.state.catalog.frontTyreDiameter, frontTyreWear: 0, privateExclVatPrice: 0,  professionalExclVatPrice: 0 }
        this.$store.state.trademodel.car.frontTyreWear = 0

      }
      if (id === 'av50') {
        $('#av0').removeClass('ar0S')
        $('#av50').addClass('ar50S')
        $('#av100').removeClass('ar100S')
        this.$store.state.trademodel.car.frontTyreWear = 50
        this.frontTyreWear = { tax: 20, type: 2, tyreSize: this.$store.state.catalog.frontTyreDiameter, frontTyreWear: 50, privateExclVatPrice: 50,  professionalExclVatPrice: 50 }

      }
      if (id === 'av100') {
        $('#av0').removeClass('ar0S')
        $('#av50').removeClass('ar50S')
        $('#av100').addClass('ar100S')
        this.$store.state.frontTyreWear = { tax: 20, type: 2, tyreSize: this.$store.state.catalog.frontTyreDiameter, frontTyreWear: 100, privateExclVatPrice: 150,  professionalExclVatPrice: 150 }
        this.$store.state.trademodel.car.frontTyreWear = 100
      }
    },
  }
}
</script>

<style scoped>

</style>