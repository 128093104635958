<template>
  <div id="app">
    <div id="nav">
      <div class="relative  bg-white pt-2" style="width: 100vw;height:100vh; overflow:auto;">
        <!--   LOADER      -->
        <div v-if="$store.state.loading" class="w-full h-full absolute block top-0 left-0 bg-white opacity-90 z-50" @click="$store.state.loading=false">
              <span class="text-green-500 opacity-75 top-1/2 my-0 mx-auto block relative w-0 h-0" style="top: 36%;width:120px;height:120px;">
                <img width="120" height="120" src="./assets/loader.gif" alt="car estimation">
                <img width="200" height="100" src="./assets/x.png" alt="X Car 2.0">
              </span>
        </div>

        <!-- ERROR MODAL -->
        <div id="errorModalId" class="flex flex-col p-8 bg-gray-800 shadow-md hover:shodow-lg rounded-2xl absolute w-2/3 left-0 right-0 ml-auto mr-auto" style="z-index:8000!important;">
          <div class="flex flex-wrap items-center justify-between">
            <div class="flex items-center">
              <div class="flexRow  ml-3 bg-white" >
                <div v-if="$store.state.image" class="ml-auto mr-auto" >
                  <img class="mr-auto ml-auto" width="640" height="480" :src="$store.state.imageUrl" alt="">
                </div>
                <div class="flexCol bg-gray-800">
                  <div class="font-medium leading-none text-gray-100"> {{$store.state.errorMsg}}</div>
                  <p class="text-sm text-gray-500 leading-none mt-2 w-full ml-auto mr-auto"> {{ $store.state.errorDescription}}</p>
                </div>
              </div>
            </div>
            <button  class="w-full mt-5 bg-red-500 px-5 ml-4 py-2 text-sm shadow-sm hover:shadow-lg font-medium tracking-wider border-2 border-red-500 text-white rounded-full"  @click="closeError">Fermer</button>

          </div>
        </div>
        <!--   NAV BAR       -->
        <div class="w-full text-white bg-white rounded text-left overflow-y-auto overflow-x-hidden relative" style="height:8%;">
          <div class=" text-black flexRow rounded mb-1" style="height:50px;">

            <div class="w-full h-full ">
              <div class="w-full md:w-1/2 ml-auto mr-auto">
                <div class="flexRow">
                 
                  <div  @click="back">
                  <LeftArrow style="height:25px; width:25px; cursor: pointer"  fill="#085c92" ></LeftArrow>
                </div>
                  <div class="font-bold text-red-600 relative  text-center w-3/4 md:w-1/2 ml-auto mr-auto p-1 md:text-md textSm" style=" font-size: .8rem!important; text-shadow: rgba(255,0,0,0.31) 1px 1px 1px; height:30px;" > VOTRE ESTIMATION GRATUITE </div>
                  <div v-if="$store.state.maxStept > $store.state.stept"  @click="next">
                    <RightArrow  style="height:20px; width:20px; cursor: pointer" fill="#085c92" stroke="gray" ></RightArrow>
                  </div>
                  <RightArrow v-else style="height:25px; width:25px; cursor:not-allowed"  fill="gray" ></RightArrow>
                </div>

                <div class="progress-bar mt-2 w-3/4 md:w-1/2">
                <span class="bar">
                <span class="progress"></span>
                </span>
                </div>
              </div>

            </div>
          </div>
        </div>
        <!--  Selecteur Etape     -->
        <Step0 v-if="$store.state.stept === 0"></Step0>
        <Step1 v-if="$store.state.stept === 1"></Step1>
        <StepHalf v-if="$store.state.stept === 0.5"></StepHalf>
        <Step2 v-if="$store.state.stept === 2"></Step2>
        <Step3to5  v-if="$store.state.stept > 2 && $store.state.stept <= 5"></Step3to5>
        <Step5Half v-if="$store.state.stept === 5.5"></Step5Half>
        <Step6 v-if="$store.state.stept === 6"></Step6>
        <Step7 v-if="$store.state.stept === 7"></Step7>
        <Step8 v-if="$store.state.stept === 8"></Step8>
        <Step9 v-if="$store.state.stept === 9"></Step9>
        <Step10 v-if="$store.state.stept === 10"></Step10>
      </div>

    </div>

  </div>
</template>
<script>
import RightArrow from '@/assets/images/RightArrow.vue'
import LeftArrow from '@/assets/images/LeftArrow.vue'
import Step0 from "@/components/Step0";
import Step1 from "@/components/Step1";
import StepHalf from "@/components/StepHalf";
import Step2 from "@/components/Step2";
import Step3to5 from "@/components/Step3to5";
import Step5Half from "@/components/Step5Half";
import Step6 from "@/components/Step6";
import Step7 from "@/components/Step7";
import Step8 from "@/components/Step8";
import Step9 from "@/components/Step9";
import Step10 from "@/components/Step10";
import jQuery from "jquery";
const $ = jQuery
export default {
  components: {RightArrow, LeftArrow, Step0, Step1, StepHalf, Step2, Step3to5,Step5Half, Step6, Step7, Step8, Step9, Step10},
  mounted () {
    this.getTokenFunc()
    if (window.matchMedia("(min-width: 600px)").matches) {
      this.$store.state.step1 = 'Commencer'
      this.$store.state.step2 = 'Votre véhicule'
      this.$store.state.step3 = 'Informations'
      this.$store.state.step4 = 'Votre estimation'
    } else {
      this.$store.state.step1 = '1'
      this.$store.state.step2 = '2'
      this.$store.state.step3 = '3'
      this.$store.state.step4 = '4'

    }
  },
  computed: {
    step() {
      return this.$store.state.stept
    }
  },
  watch: {
    step(newStep, oldStep) {
      switch (newStep) {
        case 0:
          this.stepin(0, 0)
          break
        case 0.5:
        this.stepin(1, 10)
          console.log(newStep)
          break
        case 1:
        this.stepin(1, 10)
        console.log('Je suis bien step 1')
        break
        case 2:
        this.stepin(2, 20)
        break
        case 3:
        this.stepin(3, 30)
        break
        case 4:
        this.stepin(4, 40)
        break
        case 5:
        this.stepin(5, 50)
        break
        case 5.5:
        this.stepin(5, 40)

        break
        case 6:
        this.stepin(6, 60)
        break
        case 7:
        this.stepin(7, 70)
        break
        case 8:
          this.stepin(8, 80)
          break
        case 9:
          this.stepin(9, 90)
          break
        case 10:
          this.stepin(10, 100)
          break
      }
    }
  },
  methods: {
    back () {
      console.log('Back', this.$store.state.stept)
      if (this.$store.state.stept === 0.5  ){
        this.$store.state.stept = 0
      }
      if (this.$store.state.stept === 1  ){
        this.$store.state.stept = 0
      }
      if (this.$store.state.stept === 2  && this.$store.state.manual === false){
        this.$store.state.stept = 1
      }
      if (this.$store.state.stept === 2  && this.$store.state.manual === true){
        this.$store.state.stept = .5
      }
      if (this.$store.state.stept === 3  ){
        this.$store.state.stept = 2
      }
      if (this.$store.state.stept === 5.5 ){
        this.$store.state.stept = 3
      }
      if (this.$store.state.stept === 5  ){
        this.$store.state.stept = 3
      }
      if (this.$store.state.stept === 6  ){
        this.$store.state.stept = 5
      }
      if (this.$store.state.stept === 7 && this.$store.state.options === false){
        this.$store.state.stept = 5.5
      }
      if (this.$store.state.stept === 7 && this.$store.state.options === true){
        this.$store.state.stept = this.$store.state.stept -1
      }
      if (this.$store.state.stept === 8 && this.$store.state.declareDmg === false){
        this.$store.state.stept = 6
      }
      if (this.$store.state.stept === 8 && this.$store.state.declareDmg === true){
        this.$store.state.stept = 7
      }
      if(this.$store.state.stept === 9 && this.$store.state.estimation === false){
        this.$store.state.estimation = true
      }
      if(this.$store.state.stept === 9 && this.$store.state.estimation === true) {
        this.$store.state.stept = 7
      }
      if(this.$store.state.stept === 10) {
        this.$store.state.essaiSms = 2
        this.$store.state.trademodel.customer.marketingConsent = true
        this.$store.state.errors = []
        this.$store.state.estimation = true
        this.$store.state.stept = 9
      }
    },
    next () {
      if (this.$store.state.maxStept > this.$store.state.stept) {
        if (this.$store.state.stept === 9 ) this.$store.state.stept = 10
        if (this.$store.state.stept === 8 ) this.$store.state.stept = 9
        if (this.$store.state.stept === 7 && this.$store.state.declareDmg === false ) {
          this.$store.state.stept = 9
        }
        if (this.$store.state.stept === 7 && this.$store.state.declareDmg === true ) {
          this.$store.state.stept = 8
        }
        if (this.$store.state.stept === 5.5 && this.$store.state.options === false){
          this.$store.state.stept = 7
        }
        if (this.$store.state.stept === 5.5 && this.$store.state.options === true){
          this.$store.state.stept = 6
        }
        if (this.$store.state.stept === 5) this.$store.state.stept = 5.5
        if (this.$store.state.stept === 4) this.$store.state.stept = 5.5
        if (this.$store.state.stept === 3) this.$store.state.stept = 5.5
        if (this.$store.state.stept === 2) this.$store.state.stept = 3
        if (this.$store.state.stept === 1) this.$store.state.stept = 2
        if (this.$store.state.stept === 0.5) this.$store.state.stept = 2
        if (this.$store.state.stept === 0) this.$store.state.stept = 1
      }
    },
    
    stepin (step, percent) {
      console.log(percent, '%')
      const percentPerStep = this.$store.state.stept * 10
      console.log(percentPerStep)
      $('.progress').animate({
        width: `${percentPerStep}%`
      }, 200)
      console.log($('.progress'))

      // this.stept = step
    },
    gtor (step) {
      for (let i = 10; i >= step; i--) {
        $('#step' + i).removeClass('green')
        $('#step' + i).addClass('red')
      }
    },
    rtog (step) {
      for (let i = 0; i <= step; i++) {
        $('#step' + i).removeClass('red')
        $('#step' + i).addClass('green')
      }
    },
    closeError() {
      $('#errorModalId').animate({
        top: "-600px"
      }, 1000, 'linear', () => {
        this.$store.state.errorMsg = ''
        this.$store.state.errorDescription = ''
      })
    },
    getTokenFunc () {
      const datas = {
        id: process.env.VUE_APP_TOKEN_WEBSERVICE,
        ip: '',
        domain: '',
        pointOfSaleId: ''
      }
      this.axios.post(this.$store.state.getToken, datas).then((json) => {
        this.$store.state.token = json.data.token
      }).catch((error) => {
        console.log(error)
      })

    },
  },

}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
body {
  background: #eef1f3;
}

.progress-bar {
  border-radius: 60px;
  overflow: hidden;
  width: 100%;
}
.progress-bar span {
  display: block;
}

.bar {
  background: rgba(0, 0, 0, 0.075);
}

.progress {

  background: #75b800;
  color: #fff;
  padding: 3px;
  width: 0%;
}

.progress-bar {
  left: 50%;
  max-width: 60%;
  position: absolute;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
}
</style>
