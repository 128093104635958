<template>
<svg width="800px" height="800px" viewBox="-0.98 0 20.001 20.001" xmlns="http://www.w3.org/2000/svg">
  <g id="seat-car" transform="translate(-2.983 -1.999)">
    <path id="secondary" :fill="fill" d="M7.38,15a1,1,0,0,1,.9.55A2.61,2.61,0,0,0,10.62,17h2.94a2.61,2.61,0,0,0,2.34-1.45,1,1,0,0,1,.9-.55h1.62L19,8.68a1,1,0,0,0-.55-1L17.06,7l-.81-3.24a1,1,0,0,0-1-.76H8.72a1,1,0,0,0-1,.76L6.94,7l-1.39.69a1,1,0,0,0-.55,1L5.58,15Z"/>
    <path id="primary" d="M5.58,15,5,8.68a1,1,0,0,1,.55-1L6.94,7l.81-3.24a1,1,0,0,1,1-.76h6.56a1,1,0,0,1,1,.76L17.06,7l1.39.69a1,1,0,0,1,.55,1L18.42,15H16.8a1,1,0,0,0-.9.55A2.61,2.61,0,0,1,13.56,17H10.62a2.61,2.61,0,0,1-2.34-1.45,1,1,0,0,0-.9-.55Zm10.32.55h0A2.61,2.61,0,0,1,13.56,17H10.62a2.61,2.61,0,0,1-2.34-1.45h0a1,1,0,0,0-.9-.55H5a1,1,0,0,0-1,1.16l.53,3.17a2,2,0,0,0,2,1.67h11a2,2,0,0,0,2-1.67L20,16.16A1,1,0,0,0,19,15H16.8a1,1,0,0,0-.9.55Z" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
  </g>
</svg></template>    
<script>
export default{
    props: {
        fill: {
            type: String,
        }
    }
}
</script>
