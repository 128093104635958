<template>
  <div id="Step8"  class="relative ml-auto mr-auto" style="width:70vw;" >
    <div class="w-full flexCol">
      <h1 class="text-black font-bold" style="font-family: Calibri,serif; font-size: 16px"> Déclaration des dégâts subis du véhicule :</h1>
      <div class="w-full respShow textSm text-center mx-auto mb-1">  Appuyez sur <span class="x-3 mr-1 ml-1 p-1 text-bold h-3" style="background-color: #4bb624;color:white;border-top-left-radius:6px;line-height: 6px;">&#8592</span> ou <span class="x-3 mr-1 ml-1 p-1 text-bold h-4" style="background-color: #4bb624;color:white;border-top-right-radius:6px;line-height: 6px;">&#8594</span> tourner le véhicule</div>
      <div style="font-size: 10px;" class="respShow"> Puis séléctionnez la partie abîmée </div>
      <div style="font-size: 10px;" class="respShow text-red-500 underline"> Appuyez plusieurs fois sur une partie abimée pour changer l'état des dégâts</div>
      <div class="border border-gray-200 w-full mb-2"></div>
      <div class="ml-auto mr-auto text-center w-full flex flex-wrap mb-2 respHide" style=" display:block; color:black; font-family:Calibri,serif; font-size:18px; font-weight:bold;">Faites pivoter la voiture, cliquez sur la partie endommagée, cliquez encore si les dégâts sont importants : <button class="border hover:bg-blue-500 hover:text-white transition border-blue-500 p-1 rounded text-blue-500"  @click="imatPb">Démonstration</button></div>
    </div>
    <div class="flexRow mx-auto">
      <div class=" md:w-2/3 w-1/2 rounded-xl mx-auto">
        <div class="flex-wrap containerAuto rounded-xl w-full flex relative overflow-hidden ml-auto mr-auto" style="min-height:45vh;">
          <div class=" h-auto  w-full" >
            <span id="arrowAuto" class="w-full h-full"> <span id="addCircle"></span></span>

            <div class="flexRow">
              <button class="ltr ml-auto mr-2 respShow button1 mx-auto mb-1 font-bold" @click="handleClick" style="width:50%!important; font-size: 30px!important; height:fit-content;padding:2px!important;height:35px; line-height:30px;"> &#8592</button>
              <button class="rtl mr-2 ml-auto respShow button1 mx-auto mb-1 font-bold" @click="handleClick" style="width:50%!important; font-size: 30px!important; height:fit-content;padding:2px!important;height:35px; line-height:30px;"> &#8594</button>
            </div>


            <div class="flex flex-wrap w-full">

              <div id="carRotate" @click="handleClick" @mouseover="HoverDamage" class="car containerAuto w-1/3 relative h-2/3 ">
                <div class="carRotate2  w-full h-full">
                  <img width="640" height="480" src="static/3p/leftToFront.gif" alt="">
                  <div id="partName" class="ml-auto mr-auto mt-auto mb-auto respHide" style="color:rgba(54,51,51,0.85);text-align: center;font-size: 18px; font-weight: bold;">Selectionnez la pièce de carrosserie </div>
                  <img id="toR" class="ltr arrowRot respHide"  style="position:absolute; left:50px; top:100px;" src="static/rotationToR.png" width="200px" height="80px" alt="xcar">
                  <img id="toL" class="rtl arrowRot respHide" style="position:absolute; right:50px; top:100px;" src="static/rotationToL.png" width="200px" height="80px" alt="xcar">
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>

      <div class="md:w-1/3 w-1/2 flexRow  ml-auto">
        <div class="flexRow  w-full h-full">
          <div class="flexCol w-full bg-white border rounded-xl">
            <div class="text-black text-center font-bold textXs">Dégâts déclarés : </div>
            <div class="w-full scroll overflow-y-auto" style="height:auto">
              <div class="defectArray border m-1 bg-white rounded text-left pl-2 font-semibold textXs " v-for="data in $store.state.defectArray" :key="data.id" :class="data.css" style="width:90%!important;color:black;">
                {{data.defectWording}}  </div>
            </div>

          </div>
        </div>
      </div>

    </div>
    <button v-if="$store.state.defectArray.length > 0" class="button1 ml-auto mr-auto w-full md:w-1/6 mt-2" style="vertical-align:middle" @click="next"><span>Valider</span></button>
    <button v-else class="button1 ml-auto mr-auto w-full md:w-1/6 mt-2" style="vertical-align:middle;" @click="next"><span>Le véhicule est intact</span></button>
  </div>
</template>

<script>
import jQuery from "jquery";
const $ = jQuery
export default {
  name: "Step8.vue",
  beforeMount() {


    if (this.$store.state.declareDmg === false){
      this.next()
    }
    setTimeout(() => {
      this.rightToLeft('static/3p/backToRight.gif')
      console.log('Test')
      console.log(this.$store.state.carState)
    }, 1000)

  },
  mounted(){
    $('.progress').animate({
        width: `80%`
      }, 200)
  },
  methods: {
    next(){
      console.log(this.$store.state.trademodel)
      console.log(this.$store.state.defectArray)
      if(this.$store.state.defectArray[0] === 0) this.$store.state.defectArray.splice(0, 1)
      console.log(this.$store.state.defectArray)

      this.$store.state.stept = 9
      if (this.$store.state.maxStept < this.$store.state.stept) this.$store.state.maxStept = 9
    },
    labelAndKey (id) {
      switch (id) {
        case 'retroDroit':
          this.$store.state.selectedLabel = 'Le rétroviseur droit '
          this.$store.state.trueLabel = 'Rétroviseur droit'
          break
        case 'feuAvDroit':
          this.$store.state.selectedLabel = 'Le phare avant droit '
          this.$store.state.trueLabel = 'Phare avant droit'
          break
        case 'feuAvGauche':
          this.$store.state.selectedLabel = 'Le phare avant gauche '
          this.$store.state.trueLabel = 'Phare avant gauche'
          break
        case 'feuArDroit':
          this.$store.state.selectedLabel = 'Le phare arrière gauche '
          this.$store.state.trueLabel = 'Phare avant gauche'
          break
        case 'feuArGauche':
          this.$store.state.selectedLabel = 'Le phare arrière droit '
          this.$store.state.trueLabel = 'Phare avant droit'
          break
        case 'pareBriseAr':
          this.$store.state.selectedLabel = 'Le parebrise arrière '
          this.$store.state.trueLabel = 'Parebrise arrière'
          break
        case 'capotAv':
          this.$store.state.selectedLabel = 'Le capot avant '
          this.$store.state.trueLabel = 'Capot avant'
          break
        case 'capotAr':
          this.$store.state.selectedLabel = 'Le coffre '
          this.$store.state.trueLabel = 'Coffre arrière'
          break
        case 'retroGauche':
          this.$store.state.selectedLabel = 'Le rétroviseur gauche '
          this.$store.state.trueLabel = 'Rétroviseur gauche'
          break
        case 'porteAvG':
          this.$store.state.selectedLabel = 'La porte avant gauche '
          this.$store.state.trueLabel = 'Porte avant gauche'
          break
        case 'porteAvD':
          this.$store.state.selectedLabel = 'La porte avant droite '
          this.$store.state.trueLabel = 'Porte avant droite'
          break
        case 'porteArD':
          this.$store.state.selectedLabel = 'La porte arrière droite '
          this.$store.state.trueLabel = 'Porte arrière droite'
          break
        case 'porteArG':
          this.$store.state.selectedLabel = 'La porte arrière gauche '
          this.$store.state.trueLabel = 'Porte arrière gauche'
          break
        case 'aileAvG':
          this.$store.state.selectedLabel = "L'aile avant gauche "
          this.$store.state.trueLabel = 'Aile avant gauche'
          break
        case 'aileAvD':
          this.$store.state.selectedLabel = "L'aile avant droite "
          this.$store.state.trueLabel = 'Aile avant droite'
          break
        case 'aileArD':
          this.$store.state.selectedLabel = "L'aile arrière  droite "
          this.$store.state.trueLabel = 'Aile arrière droite'
          break
        case 'aileArG':
          this.$store.state.selectedLabel = "L'aile arrière gauche "
          this.$store.state.trueLabel = 'Aile arrière gauche'
          break
        case 'basPorteD':
          this.$store.state.selectedLabel = 'Le bas porte droite '
          this.$store.state.trueLabel = 'Bas porte droit'
          break
        case 'basPorteG':
          this.$store.state.selectedLabel = 'Le Bas porte gauche '
          this.$store.state.trueLabel = 'Bas porte gauche'
          break
        case 'roueAvG':
          this.$store.state.selectedLabel = 'La jante avant gauche '
          this.$store.state.trueLabel = 'Jante avant gauche'
          break
        case 'roueAvD':
          this.$store.state.selectedLabel = 'La jante avant droite '
          this.$store.state.trueLabel = 'Jante avant droite'
          break
        case 'roueArG':
          this.$store.state.selectedLabel = 'La jante arrière gauche '
          this.$store.state.trueLabel = 'Jante arrière gauche'
          break
        case 'pareBriseAv':
          this.$store.state.selectedLabel = 'Le Pare brise avant '
          this.$store.state.trueLabel = 'Parebrise avant'
          break
        case 'roueArD':
          this.$store.state.selectedLabel = 'La jante arrière droite '
          this.$store.state.trueLabel = 'Jante arrière droite'
          break
        case 'pareChocAv':
          this.$store.state.selectedLabel = 'Le parechoc avant '
          this.$store.state.trueLabel = 'Parechoc avant'
          break
        case 'pareChocAr':
          this.$store.state.selectedLabel = 'Le parechoc arrière '
          this.$store.state.trueLabel = 'Parechoc arrière'
          break
      }
    },
    HoverDamage (e) {
      if (e.target.matches('.click, .play-video *')) {
        this.labelAndKey(e.target.id)
        $('#partName').html(this.$store.state.selectedLabel)
        $('#nbDmg').html(' ')
        $('#nbDmg').html(' ' + this.$store.state.nbDamage)
        if (e.target.id === 'roueAvD' ||
            e.target.id === 'roueAvG' ||
            e.target.id === 'roueArD' ||
            e.target.id === 'roueArG' ||
            e.target.id === 'pareBriseAv' ||
            e.target.id === 'pareBriseAr' ||
            e.target.id === 'feuAvDroit' ||
            e.target.id === 'feuAvGauche' ||
            e.target.id === 'feuArGauche' ||
            e.target.id === 'feuArDroit'
        ){
          if ($(`#${e.target.id}`).hasClass('veryHigh')) {
            $('#nbDmg').removeClass('lowText')
            $('#nbDmg').removeClass('noneText')
            $('#nbDmg').addClass('veryHighText')
          }
          if ($(`#${e.target.id}`).hasClass('low')) {
            $('#nbDmg').removeClass('noneText')
            $('#nbDmg').removeClass('veryHighText')
            $('#nbDmg').addClass('lowText')
          }
          if ($(`#${e.target.id}`).hasClass('none')) {
            $('#nbDmg').removeClass('veryHighText')
            $('#nbDmg').removeClass('lowText')
            $('#nbDmg').addClass('noneText')
          }
        } else {
          if ($(`#${e.target.id}`).hasClass('veryHigh')) {
            $('#nbDmg').removeClass('mediumText')
            $('#nbDmg').removeClass('lowText')
            $('#nbDmg').removeClass('noneText')
            $('#nbDmg').removeClass('highText')
            $('#nbDmg').addClass('veryHighText')
          }
          if ($(`#${e.target.id}`).hasClass('high')) {
            $('#nbDmg').removeClass('mediumText')
            $('#nbDmg').removeClass('lowText')
            $('#nbDmg').removeClass('noneText')
            $('#nbDmg').removeClass('veryHighText')
            $('#nbDmg').addClass('highText')
          }
          if ($(`#${e.target.id}`).hasClass('medium')) {
            $('#nbDmg').removeClass('highText')
            $('#nbDmg').removeClass('lowText')
            $('#nbDmg').removeClass('noneText')
            $('#nbDmg').removeClass('veryHighText')
            $('#nbDmg').addClass('mediumText')
          }
          if ($(`#${e.target.id}`).hasClass('low')) {
            $('#nbDmg').removeClass('mediumText')
            $('#nbDmg').removeClass('hightTest')
            $('#nbDmg').removeClass('noneText')
            $('#nbDmg').removeClass('veryHighText')
            $('#nbDmg').addClass('lowText')
          }
          if ($(`#${e.target.id}`).hasClass('none')) {
            $('#nbDmg').removeClass('mediumText')
            $('#nbDmg').removeClass('hightText')
            $('#nbDmg').removeClass('lowText')
            $('#nbDmg').removeClass('veryHighText')
            $('#nbDmg').addClass('noneText')
          }
        }
      } else {

      }

    },
    imatPb () {
      const addArrow = $('#arrowAuto')
      const porteArG = $('#porteArG')
      const arrayDefect = $('.defectArray')
      addArrow.removeClass('arrow')
      addArrow.addClass('arrow')
      setTimeout(() => {
        this.$store.state.carState = 'static/3p/leftToFront.gif'
        addArrow.css('backgroundImage', 'url(./static/cursor2.png)')
        setTimeout(() => {
          addArrow.css('backgroundImage', 'url(./static/cursor1.png)')
        },500)

        this.rightToLeft(this.$store.state.carState)
      }, 2500)
      setTimeout(() => {
        addArrow.css('backgroundImage', 'url(./static/cursor2.png)')
        setTimeout(() => {
          addArrow.css('backgroundImage', 'url(./static/cursor1.png)')
        },500)
        this.$store.state.defectArray = [{defectWording: 'Porte arrière gauche bosselée'}]


        porteArG.addClass('low')
      }, 7000)
      setTimeout(() => {
        addArrow.css('backgroundImage', 'url(./static/cursor2.png)')
        setTimeout(() => {
          addArrow.css('backgroundImage', 'url(./static/cursor1.png)')
        },500)
        arrayDefect.removeClass('border')
        arrayDefect.addClass('border-red-500')
        arrayDefect.addClass('border-2')
        this.$store.state.defectArray = [{defectWording: 'Porte arrière gauche rayée'}]

        porteArG.removeClass('low')
        porteArG.addClass('medium')
      }, 8000)
      setTimeout(() => {
        addArrow.css('backgroundImage', 'url(./static/cursor2.png)')
        setTimeout(() => {
          addArrow.css('backgroundImage', 'url(./static/cursor1.png)')
        },500)
        this.$store.state.defectArray = [{defectWording: 'Porte arrière gauche enfoncée'}]

        porteArG.removeClass('medium')
        porteArG.addClass('high')
      }, 9000)
      setTimeout(() => {
        addArrow.css('backgroundImage', 'url(./static/cursor2.png)')
        setTimeout(() => {
          addArrow.css('backgroundImage', 'url(./static/cursor1.png)')
        },500)
        this.$store.state.defectArray = [{defectWording: 'Porte arrière gauche à remplacer'}]
        porteArG.removeClass('high')
        porteArG.addClass('veryHigh')

      }, 10000)
      setTimeout(() => {
        arrayDefect.removeClass('border-red-500')
        arrayDefect.removeClass('border-2')
        arrayDefect.addClass('border')
        this.leftToRight(this.$store.state.carState)
        addArrow.removeClass('arrow')
        this.$store.state.defectArray = []
      }, 12000)

    },
    getDmgSection (search) {
      const list = this.$store.state.damageSectionList.items
      list.forEach((section) => {
        if (section.value === search) {
          this.$store.state.dmgSectionLabel = section.value
          this.$store.state.selectedKey = section.key
        }
      })
    },
    arrayClean (id) {
      const index = this.$store.state.defectArray.findIndex(function (o) {
        return o.id2 === id
      })
      if (index !== -1) this.$store.state.defectArray.splice(index, 1)
    },
    handleClick (e) {
  
      console.log(this.$store.state.defectArray)
      if (e.target.matches('.click, .play-video *')) {
        this.damageSection(e.target.id)
        this.getDmgSection(this.$store.state.trueLabel)
        const bearer = `Bearer ${this.$store.state.token}`
        this.axios.get(`https://scoring-api.openflex.eu/pricing-policies/2/estimated-damages/${this.$store.state.estimatedDamageId}/sections/${this.$store.state.selectedKey}/defects`, {
          headers: { Authorization: bearer }
        }).then((response) => {
          // A  of data has been received.
          console.log(response.data.section)
          this.$store.state.defectSection = response.data.section
          // this.getData(`https://scoring-api.openflex.eu/pricing-policies/2/estimated-damages/${this.estimatedDamageId}/sections/${this.selectedKey}/defects`, '', 8)
          $('#appendForDmg').append('<div class="mr-auto mt-auto mb-auto" id="nbDmg" ></div>')
          $('#nbDmg').html(' ' + this.$store.state.nbDamage)

          if ($(`#${e.target.id}`).hasClass('none')) {
            console.log('None')

            this.$store.state.defect[e.target.id] = {  section: this.$store.state.selectedKey, sectionValue: this.$store.state.selectedLabel, totalDefects: 0, defectWording: 'Intact', css: 'none' }
            this.arrayClean(e.target.id)
          }
          if ($(`#${e.target.id}`).hasClass('low')) {
            console.log('Low')
            this.$store.state.defect[e.target.id] = { section: this.$store.state.selectedKey, sectionValue: this.$store.state.selectedLabel, totalDefects: 1, defectWording: 'Bosselée(e)', css: 'low' }
            this.arrayClean(e.target.id)
            console.log(this.$store.state.defectSection.defects)
            if (this.$store.state.defectSection.defects && this.$store.state.defectSection.defects.length > 0 && (this.$store.state.defectSection.defects[0].defectWording === "1 éclat" || this.$store.state.defectSection.defects[0].defectWording === 'Rayé(e)')) {
              this.$store.state.defectArray.push({ id2: e.target.id, type: 3,  sectionValue: this.$store.state.selectedLabel, defectWording: this.$store.state.defectSection.sectionValue + ' ' + this.$store.state.defectSection.defects[0].defectWording, id: this.$store.state.defectSection.defects[0].id, privateExclVatPrice: this.$store.state.defectSection.defects[0].privatePrice, professionalExclVatPrice: this.$store.state.defectSection.defects[0].professionalPrice, privateInclVatPrice: this.$store.state.defectSection.defects[0].privatePrice, professionnalIncVatPrice: this.$store.state.defectSection.defects[0].professionalPrice, tax: 20, section: this.$store.state.defectSection.section, defectId: this.$store.state.defectSection.defects[0].defectId, css: 'low' } )
            } else {
              this.$store.state.defectArray.push({ id2: e.target.id, type: 3,  sectionValue: this.$store.state.selectedLabel, defectWording: this.$store.state.defectSection.sectionValue + ' ' + this.$store.state.defectSection.defects[1].defectWording, id: this.$store.state.defectSection.defects[1].id, privateExclVatPrice: this.$store.state.defectSection.defects[1].privatePrice, professionalExclVatPrice: this.$store.state.defectSection.defects[1].professionalPrice, privateInclVatPrice: this.$store.state.defectSection.defects[1].privatePrice, professionnalIncVatPrice: this.$store.state.defectSection.defects[1].professionalPrice, tax: 20, section: this.$store.state.defectSection.section, defectId: this.$store.state.defectSection.defects[1].defectId, css: 'low' } )
            }

          }

          if ($(`#${e.target.id}`).hasClass('medium')) {
            console.log('medium')

            this.$store.state.defect[e.target.id] = { section: this.$store.state.selectedKey, sectionValue: this.$store.state.selectedLabel, totalDefects: 2, defectWording: 'Rayé(e)', css: 'medium' }
            this.arrayClean(e.target.id)
            this.$store.state.defectArray.push({ id2: e.target.id, type: 3,  sectionValue: this.$store.state.selectedLabel, defectWording: this.$store.state.defectSection.sectionValue + ' ' + this.$store.state.defectSection.defects[3].defectWording, id: this.$store.state.defectSection.defects[3].id, privateExclVatPrice: this.$store.state.defectSection.defects[3].privatePrice, professionalExclVatPrice: this.$store.state.defectSection.defects[3].professionalPrice, privateInclVatPrice: this.$store.state.defectSection.defects[3].privatePrice, professionnalIncVatPrice: this.$store.state.defectSection.defects[3].professionalPrice, tax: 20, section: this.$store.state.defectSection.section, defectId: this.$store.state.defectSection.defects[3].defectId, css: 'medium' } )
          }
          if ($(`#${e.target.id}`).hasClass('high')) {
            this.$store.state.defect[e.target.id] = { section: this.$store.state.selectedKey, sectionValue: this.$store.state.selectedLabel, totalDefects: 3, defectWording: 'Enfoncé(e)', css: 'high' }
            this.arrayClean(e.target.id)
            this.$store.state.defectArray.push({ id2: e.target.id, type: 3,  sectionValue: this.$store.state.selectedLabel, defectWording:  this.$store.state.defectSection.sectionValue + ' ' + this.$store.state.defectSection.defects[2].defectWording, id: this.$store.state.defectSection.defects[2].id, privateExclVatPrice: this.$store.state.defectSection.defects[2].privatePrice, professionalExclVatPrice: this.$store.state.defectSection.defects[2].professionalPrice, privateInclVatPrice: this.$store.state.defectSection.defects[2].privatePrice, professionnalIncVatPrice: this.$store.state.defectSection.defects[2].professionalPrice, tax: 20, section: this.$store.state.defectSection.section, defectId: this.$store.state.defectSection.defects[2].defectId, css: 'high' } )
          }
          if ($(`#${e.target.id}`).hasClass('veryHigh')) {
            this.$store.state.defect[e.target.id] = { section: this.$store.state.selectedKey, sectionValue: this.$store.state.selectedLabel, totalDefects: 4, defectWording: 'à remplacer', css: 'veryHigh' }
            this.arrayClean(e.target.id)
            if (this.$store.state.defectSection.defects[0].defectWording === "1 éclat" ) {
              this.$store.state.defectArray.push({ id2: e.target.id, type: 3,  sectionValue: this.$store.state.selectedLabel, defectWording: this.$store.state.defectSection.sectionValue + ' ' + this.$store.state.defectSection.defects[1].defectWording, id: this.$store.state.defectSection.defects[1].id, privateExclVatPrice: this.$store.state.defectSection.defects[1].privatePrice, professionalExclVatPrice: this.$store.state.defectSection.defects[1].professionalPrice, privateInclVatPrice: this.$store.state.defectSection.defects[1].privatePrice, professionnalIncVatPrice: this.$store.state.defectSection.defects[1].professionalPrice, tax: 20, section: this.$store.state.defectSection.section, defectId: this.$store.state.defectSection.defects[1].defectId, css: 'veryHigh'} )
            } else {
              this.$store.state.defectArray.push({ id2: e.target.id, type: 3,  sectionValue: this.$store.state.selectedLabel, defectWording: this.$store.state.defectSection.sectionValue + ' ' + this.$store.state.defectSection.defects[0].defectWording, id: this.$store.state.defectSection.defects[0].id, privateExclVatPrice: this.$store.state.defectSection.defects[0].privatePrice, professionalExclVatPrice: this.$store.state.defectSection.defects[0].professionalPrice, privateInclVatPrice: this.$store.state.defectSection.defects[0].privatePrice, professionnalIncVatPrice: this.$store.state.defectSection.defects[0].professionalPrice, tax: 20, section: this.$store.state.defectSection.section, defectId: this.$store.state.defectSection.defects[0].defectId, css: 'veryHigh'} )

            }
          }
        })
      }
      if (e.target.matches('.rtl')) {
        this.rightToLeft(this.$store.state.carState)
        Object.entries(this.$store.state.defect).forEach(entry => {
          const [key, value] = entry
          $(`#${key}`).addClass(value.css)
        })
      }
      if (e.target.matches('.ltr')) {
        this.leftToRight(this.$store.state.carState)
        Object.entries(this.$store.state.defect).forEach(entry => {
          const [key, value] = entry
          $(`#${key}`).addClass(value.css)
        })
    }

  },
  targetFront () {
    this.create('150px', '150px', 'feuAvDroit')
  },
  damageSection (id) {
    const item = $(`.${id}`)
    const nDmg = $('#nbDmg')
    if (id === 'roueAvD' ||
        id === 'roueAvG' ||
        id === 'roueArD' ||
        id === 'roueArG' ||
        id === 'pareBriseAv' ||
        id === 'pareBriseAr' ||
        id === 'feuAvDroit' ||
        id === 'feuArDroit' ||
        id === 'feuArGauche' ||
        id === 'feuAvGauche')
    {
      if (item.hasClass('none')) {
        item.removeClass('none')
        item.addClass('low')
        nDmg.removeClass('noneText')
        nDmg.addClass('lowText')
        this.$store.state.nbDamage = ' Abîmé(e)'
        return
      }
      if (item.hasClass('veryHigh')) {
        item.removeClass('veryHigh')
        item.addClass('none')
        nDmg.removeClass('noneText')
        nDmg.addClass('noneText')
        this.$store.state.nbDamage = " n'a aucun dégat"
        return
      }
      if (item.hasClass('low')) {
        item.removeClass('low')
        item.addClass('veryHigh')
        nDmg.removeClass('lowText')
        nDmg.addClass('veryHighText')
        this.$store.state.nbDamage = ' A remplacer.'

      }
    } else {

      if (item.hasClass('none')) {
        item.removeClass('none')
        item.addClass('low')
        nDmg.removeClass('noneText')
        nDmg.addClass('lowText')
        this.$store.state.nbDamage = ' est bosselé(e)'
        return
      }
      if (item.hasClass('veryHigh')) {
        item.removeClass('veryHigh')
        item.addClass('none')
        nDmg.removeClass('noneText')
        nDmg.addClass('noneText')
        this.$store.state.nbDamage = " n'a aucun dégat"
        return
      }
      if (item.hasClass('high')) {
        item.removeClass('high')
        item.addClass('veryHigh')
        nDmg.removeClass('highText')
        nDmg.addClass('veryHighText')
        this.$store.state.nbDamage = "est à remplacer"
        return
      }
      if (item.hasClass('medium')) {
        item.removeClass('medium')
        item.addClass('high')
        nDmg.removeClass('mediumText')
        nDmg.addClass('highText')
        this.$store.state.nbDamage = ' est enfoncé(e).'
        return
      }
      if (item.hasClass('low')) {
        item.removeClass('low')
        item.addClass('medium')
        nDmg.removeClass('lowText')
        nDmg.addClass('mediumText')
        this.$store.state.nbDamage = ' est rayé(e).'

      }
    }
  },
  createFrontTag () {
    const front = [
      { x: '175px', y: '113px', id: 'retroDroit', w: '50px', h: '50px', class: 'cibleCenter50', name:'Retroviseur droit' },
      { x: '431px', y: '113px', id: 'retroGauche', w: '50px', h: '50px', class: 'cibleCenter50', name:'Retroviseur gauche' },
      { x: '196px', y: '158px', id: 'feuAvDroit', w: '50px', h: '50px', class: 'cibleCenter50', name:'Feu av. droit' },
      { x: '410px', y: '158px', id: 'feuAvGauche', w: '50px', h: '50px', class: 'cibleCenter50', name:'Feu av. gauche' },
      { x: '308px', y: '99px', id: 'pareBriseAv', w: '50px', h: '50px', class: 'cibleCenter50', name:'Pare brise avant' },
      { x: '308px', y: '233px', id: 'pareChocAv', w: '50px', h: '50px', class: 'cibleCenter50', name:'Pare chocs avant' },
      { x: '308px', y: '155px', id: 'capotAv', w: '50px', h: '50px', class: 'cibleCenter50', name:'Capot av.' }
    ]
    front.forEach((e) => {
      $('.carRotate2').append(`<div id="${e.id}" class="border-2 click pl-2 text-center none border-red-500 absolute ${e.class} ${e.id}" style="width:${e.w};height:${e.h};top:${e.y}; left:${e.x}; border-radius:50%;border-style: dashed;"  @click="handleClick(${e.id})">${e.name}</div>`)
    })
  },
  createRightTag () {
    const right = [
      { x: '480px', y: '240px', id: 'roueAvD', w: '50px', h: '50px', class: 'cibleCenter50', name:'Roue av. droit' },
      { x: '100px', y: '240px', id: 'roueArD', w: '50px', h: '50px', class: 'cibleCenter50', name:'Roue ar. droit' },
      { x: '300px', y: '170px', id: 'porteAvD', w: '50px', h: '50px', class: 'cibleCenter50', name:'Porte av. droit' },
      { x: '167px', y: '170px', id: 'porteArD', w: '50px', h: '50px', class: 'cibleCenter50', name:'Porte ar. droit' },
      { x: '240px', y: '240px', id: 'basPorteD', w: '50px', h: '50px', class: 'cibleCenter50', name:'Bas porte droit' },
      { x: '115px', y: '135px', id: 'aileArD', w: '50px', h: '50px', class: 'cibleCenter50', name:'Aile ar. droit' },
      { x: '450px', y: '145px', id: 'aileAvD', w: '50px', h: '50px', class: 'cibleCenter50', name:'Aile av. droit' }
    ]
    right.forEach((e) => {
      $('.carRotate2').append(`<div id="${e.id}" class="border-2 click pl-2 text-center none border-red-500 absolute ${e.class} ${e.id}" style="width:${e.w};height:${e.h};top:${e.y}; left:${e.x}; border-radius:50%;border-style: dashed;" @click="handleClick(${e.id})">${e.name}</div>`)
    })
  },
  createLeftTag () {
    const left = [
      { x: '138px', y: '145px', id: 'aileAvG', w: '50px', h: '50px', class: 'cibleCenter50', name:'Aile av. gauche' },
      { x: '495px', y: '135px', id: 'aileArG', w: '50px', h: '50px', class: 'cibleCenter50', name:'Aile ar. gauche' },
      { x: '498px', y: '241px', id: 'roueArG', w: '50px', h: '50px', class: 'cibleCenter50', name:'Roue ar. gauche' },
      { x: '117px', y: '244px', id: 'roueAvG', w: '50px', h: '50px', class: 'cibleCenter50', name:'Roue av. gauche'},
      { x: '244px', y: '170px', id: 'porteAvG', w: '50px', h: '50px', class: 'cibleCenter50', name:'Porte av. gauche' },
      { x: '378px', y: '170px', id: 'porteArG', w: '50px', h: '50px', class: 'cibleCenter50', name:'Porte ar. gauche' },
      { x: '292px', y: '238px', id: 'basPorteG', w: '50px', h: '50px', class: 'cibleCenter50', name:'Bas porte gauche' }
    ]
    left.forEach((e) => {
      $('.carRotate2').append(`<div id="${e.id}" class="border-2 click pl-2 text-center none border-red-500 absolute ${e.class} ${e.id}" style="width:${e.w};height:${e.h};top:${e.y}; left:${e.x}; border-radius:50%;border-style: dashed;" @click="handleClick(${e.id})">${e.name}</div>`)
    })
  },
  createBackTag () {
    const back = [
      { x: '415px', y: '99px', id: 'retroDroit', w: '50px', h: '50px', class: 'cibleCenter50', name:'Retroviseur droit' },
      { x: '173px', y: '99px', id: 'retroGauche', w: '50px', h: '50px', class: 'cibleCenter50', name:'Retroviseur gauche' },
      { x: '412px', y: '145px', id: 'feuArDroit', w: '50px', h: '50px', class: 'cibleCenter50', name:'Feu ar. droit' },
      { x: '195px', y: '145px', id: 'feuArGauche', w: '50px', h: '50px', class: 'cibleCenter50', name:'Feu ar. gauche' },
      { x: '308px', y: '100px', id: 'pareBriseAr', w: '50px', h: '50px', class: 'cibleCenter50', name:'Pare brise arrière' },
      { x: '308px', y: '240px', id: 'pareChocAr', w: '50px', h: '50px', class: 'cibleCenter50', name:'Pare chocs arrière' },
      { x: '308px', y: '160px', id: 'capotAr', w: '50px', h: '50px', class: 'cibleCenter50', name:'Capot ar.' }
    ]
    back.forEach((e) => {
      $('.carRotate2').append(`<div id="${e.id}" class="border-2 click pl-2 text-center none border-red-500 absolute ${e.class} ${e.id}" style="width:${e.w};height:${e.h};top:${e.y}; left:${e.x}; border-radius:50%;border-style: dashed;" @click="handleClick(${e.id})">${e.name}</div>`)
    })
  },
  leftToRight (state) {
    const carDiv = $('.carRotate2')
    const carDiv2 = $('.carRotate2')
    carDiv.html('')
    carDiv2.html(' ')
    switch (state) {
      case 'static/3p/backToLeft.gif':
        this.$store.state.carState = 'static/3p/leftToFront.gif'
        this.$store.state.carPosition = 'front'
        this.createFrontTag()
        break
      case 'static/3p/leftToFront.gif':
        this.$store.state.carState = 'static/3p/frontToRight.gif'
        this.$store.state.carPosition = 'right'
        this.createRightTag()
        break
      case 'static/3p/frontToRight.gif':
        this.$store.state.carState = 'static/3p/rightToBack.gif'
        this.$store.state.carPosition = 'back'
        this.createBackTag()
        break
      case 'static/3p/rightToBack.gif':
        this.$store.state.carState = 'static/3p/backToLeft.gif'
        this.$store.state.carPosition = 'left'
        this.createLeftTag()
        break
      case 'static/3p/rightToFront.gif':
        this.$store.state.carState = 'static/3p/frontToRight.gif'
        this.$store.state.carPosition = 'right'
        this.createRightTag()
        break
      case 'static/3p/frontToLeft.gif':
        this.$store.state.carState = 'static/3p/leftToFront.gif'
        this.$store.state.carPosition = 'front'
        this.createFrontTag()
        break
      case 'static/3p/leftToBack.gif':
        this.$store.state.carState = 'static/3p/backToLeft.gif'
        this.$store.state.carPosition = 'left'
        this.createLeftTag()
        break
      case 'static/3p/backToRight.gif':
        this.$store.state.carState = 'static/3p/rightToBack.gif'
        this.$store.state.carPosition = 'back'
        this.createBackTag()
        break
    }
    let image = new Image()
    image.src = this.$store.state.carState
    image.width = 640
    image.height = 480

    carDiv2.append(image)
    carDiv2.append('<div id="appendForDmg" style="display:flex;flex-flow:row;" ><div id="partName" class=" ml-5 mt-auto mb-auto" style="font-size: 28px;color:rgba(54,51,51,0.85);font-weight: bold; text-align: center">Selectionnez la pièce de carrosserie </div></div>')
    $('#appendForDmg').append('<div id="nbDmg"></div>')
    carDiv2.append('<img class="ltr respHide"  style="position:absolute; left:0; top:240px;" src="/static/rotationToR.png" width="200px" height="100px" alt="xcar">')
    carDiv2.append('<img class="rtl respHide"  style="position:absolute; right:0; top:240px;" src="/static/rotationToL.png" width="200px" height="100px" alt="xcar">')
  },
  rightToLeft (state) {
    const carDiv = $('.carRotate2')
    $('.carRotate2').html(' ')
    carDiv.html('')
    switch (state) {
      case 'static/3p/backToLeft.gif':
        this.$store.state.carState = 'static/3p/leftToBack.gif'
        this.$store.state.carPosition = 'back'
        this.createBackTag()
        break
      case 'static/3p/leftToBack.gif':
        this.$store.state.carState = 'static/3p/backToRight.gif'
        this.$store.state.carPosition = 'right'
        this.createRightTag()
        break
      case 'static/3p/backToRight.gif':
        this.$store.state.carState = 'static/3p/rightToFront.gif'
        this.$store.state.carPosition = 'front'
        this.createFrontTag()
        break
      case 'static/3p/rightToFront.gif':
        this.$store.state.carState = 'static/3p/frontToLeft.gif'
        this.$store.state.carPosition = 'left'
        this.createLeftTag()
        break
      case 'static/3p/leftToFront.gif':
        this.$store.state.carState = 'static/3p/frontToLeft.gif'
        this.$store.state.carPosition = 'left'
        this.createLeftTag()
        break
      case 'static/3p/frontToRight.gif':
        this.$store.state.carState = 'static/3p/rightToFront.gif'
        this.carPosition = 'front'
        this.createFrontTag()
        break
      case 'static/3p/rightToBack.gif':
        this.$store.state.carState = 'static/3p/backToRight.gif'
        this.$store.state.carPosition = 'right'
        this.createRightTag()
        break
      case 'static/3p/frontToLeft.gif':
        this.$store.state.carState = 'static/3p/leftToBack.gif'
        this.$store.state.carPosition = 'back'
        this.createBackTag()
    }
    let image = new Image()
    image.src = this.$store.state.carState
    image.width = 640
    image.height = 480

    carDiv.append(image)
    carDiv.append('<div id="appendForDmg" style="display:flex;flex-flow:row;"><div id="partName" class="ml-5 mt-auto mb-auto" style="font-size: 25px;;color:rgba(54,51,51,0.85);font-weight: bold; text-align: center">Selectionnez la pièce de carrosserie </div></div>')
    $('#appendForDmg').append('<div id="nbDmg"></div>')
    carDiv.append('<img  class="ltr respHide" style="position:absolute; left:0; top:240px;" src="/static/rotationToR.png" width="200px" height="100px" alt="xcar">')
    carDiv.append('<img  class="rtl respHide"  style="position:absolute; right:0; top:240px;" src="/static/rotationToL.png" width="200px" height="100px" alt="xcar">')
  },
  gtor (step) {

    for (let i = 10; i >= step; i--) {
      let step = $('#step' + i)
      step.removeClass('green')
      step.addClass('red')
    }
  },
  rtog (step) {
    for (let i = 0; i <= step; i++) {
      let step = $('#step' + i)
      step.removeClass('red')
      step.addClass('green')
    }
  },
}
}
</script>

<style scoped>
img{
  height:auto!important;
  border-radius:15px!important;
}
</style>