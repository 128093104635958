<template>
  <div id="Step0" class="w-full mb-1 rounded bg-white window" style="">

    <div class="w-full border-gray-300 font-bold pb-2 text-center h-2/5">
      <!--
            <iframe style="width:600px; height:500px; background-color: black;" src="https://backend.xcar-estimation.fr/" frameborder="0"></iframe>
      -->

      <!--      <h1 class="text-gray-800 " style="font-family: Calibri; text-shadow: 1px 1px 1px rgba(0,13,79,0.48);font-size: 29px;">Estimation de la valeur de reprise de votre véhicule</h1>
            <p class="text-gray-700 italic " style="font-size:15px;">"Avec ou sans immatriculation, saisissez les caractéristiques de votre véhicule à reprendre et profitez de la meilleure offre de votre distributeur.</p>-->
      
      <div class="text-gray-700 p-2 mt-6" style="font-size: 15px;font-weight: normal;">Pour gagner du temps, recherchez
        votre véhicule avec votre plaque d'immatriculation.</div>
      <div class=" flex flex-wrap mb-1 ml-auto mr-auto " style="width:90%;">
        <h1 id="btnImatSearch"
          class="text-white mt-10 text-center border border-black rounded-md md:w-1/2 w-full  mr-auto ml-auto cursor-pointer selected"
          @click="btnImatSearch" style="text-aligne: center; font-family: Calibri; font-size: 20px; ">Recherche par immatriculation</h1>

      </div>
    </div>
    <!--    <a href="#manualSearchDiv" class="ml-auto text-center w-1/6 mr-auto mb-6 block bg-blue-500 hover:bg-blue-400 text-white font-bold py-2 px-4 border-b-4 border-blue-700 hover:border-blue-500 rounded" @click="$store.state.manualSearchButton = !$store.state.manualSearchButton"> Recherche Manuelle</a>-->

    <div id="manualSearchDiv" class="m-auto border-black bg-gray-300 p-4 rounded-md zImat relative rotateToImat"
      style="width:90%;">

      <div id="zManual" class="w-full  absolute left-0 top-0 "
        style="width:100%;transform:rotate3d(0, 1, 0, 180deg);height:85vh;">
        <div class="flexCol pt-3  bg-white rounded-md" style="height:100%;">
          <div class="flex w-full md:full mt-5">

            <div class="md:w-1/2 mx-auto w-full mb-1 ">
              <div class="md:w-3/5 w-4/5  font-bold md:text-center mb-1 ml-auto mr-auto md:mb-0 text-black"
                style="text-shadow: 1px 1px 1px rgba(26,37,47,0.33);">Mise en circulation </div>
              <div class="flexRow w-full">
                <select id="inline-full-mec3" v-model="selectedDay" name="inline-full-mec3"
                  class=" w-1/3 bg-gray-100 md:text-md text-sm bg-white ml-auto mr-1 appearance-none border-2 text-center border-gray-200 rounded w-1/4 border-l-0 py-2 border-r-0 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500">
                  <option value="31" selected="selected">31</option>
                  <option v-for="day  in days" :value="day">{{ day }}</option>
                </select>
                <select id="inline-full-mec" v-model="selectedMonth" name="month"
                  class=" w-1/3 bg-gray-100 md:text-md text-sm bg-white ml-1 mr-1 appearance-none border-2 text-center border-gray-200 rounded w-1/4 border-l-0 py-2 border-r-0 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500">
                  <option value="01" selected="selected"> Janvier </option>
                  <option value="02"> Fevrier </option>
                  <option value="03"> Mars</option>
                  <option value="04"> Avril </option>
                  <option value="05"> Mai </option>
                  <option value="06"> Juin</option>
                  <option value="07"> Juillet </option>
                  <option value="08"> Aout </option>
                  <option value="09"> Septembre</option>
                  <option value="10"> Octobre</option>
                  <option value="11"> Novembre</option>
                  <option value="12"> Decembre</option>
                </select>
                <select @change="getMarque" id="inline-full-mec2" v-model="selectedYear" name="inline-full-mec2"
                  class="w-1/3 bg-gray-100 md:text-md text-center text-sm bg-white mr-auto ml-1 appearance-none border-2 border-gray-200 rounded w-1/4 border-l-0 border-r-0 py-1 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500">
                  <option value="2022" selected="selected">2022</option>
                  <option v-for="numbe in year" :value="numbe" > {{ numbe }} </option>
                </select>
              </div>
            </div>
            <!--
                          <div class=" flex flex-col w-full md:flex-row mb-6 mt-auto">
                            <div class="md:w-2/5 w-full">
                              <label class="md:w-1/2 w-full font-bold md:text-left mb-1 md:mb-0 pr-4 pt-2" style="white-space: nowrap;text-shadow: 1px 1px 1px rgba(26,37,47,0.33); line-height: 20px;" for="putIntoServiceYear">
                                Mise en circulation
                              </label>
                            </div>
                            <div class="md:w-2/5 w-4/5 ml-auto mr-10 relative">
                              <input type="date"  @change="getMarque" style="box-shadow: 1px 1px 1px rgba(26,37,47,0.33);" v-model="$store.state.trademodel.car.putIntoService" name="putIntoServiceYear" class="bg-white appearance-none border-2 ml-0 mr-0 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="putIntoServiceYear" >
                              <div @click="showError('Ou trouver la date de mise en circulation ?', ' La date de première mise en circulation est indispensable pour nous aider à retrouver le model de votre véhicule.', 'static/mec.png', true)" class="absolute  top-4" style="width:30px; right:-30px; height:30px;cursor: pointer;"><svg viewBox="0 0 73.213 73.213" class="sc-iBzEeX glZmJR sc-oeezt nNRsM"><g transform="translate(9 6)"><g transform="translate(-9 -6)"><path d="M316.223 308.911l-12.667-12.668a24.382 24.382 0 0 1-7.32 7.319l5.972 5.972 6.7 6.7a5.175 5.175 0 0 0 7.319-7.319z" transform="translate(-253.53 -256.53)"></path></g><g transform="translate(-9 -6)"><path d="M41.411 20.7a20.7 20.7 0 1 0-20.7 20.7 20.706 20.706 0 0 0 20.7-20.7zm-20.7 15.528a15.528 15.528 0 1 1 15.524-15.523 15.545 15.545 0 0 1-15.529 15.528z" transform="translate(9 6)"></path></g><path d="M75.817 87.894h3.45a8.637 8.637 0 0 1 8.627-8.627v-3.451a12.092 12.092 0 0 0-12.077 12.078z" transform="translate(-67.19 -67.189)"></path></g></svg></div>
                            </div>
                          </div>-->
          </div>
          <div class="flex flex-wrap w-3/4 mx-auto">
            <div class=" md:w-1/2 w-full mt-auto">
              <div class="md:flex md:flex md:items-center  mb-6 mt-auto sm:w-full">
                <div class="md:w-1/5 sm:w-full">
                  <label class="md:w-full w-4/5  font-bold md:text-left mb-1 ml-auto mr-auto md:mb-0"
                    style="text-shadow: 1px 1px 1px rgba(26,37,47,0.33);" for="manualMark">
                    Marques
                  </label>
                </div>
                <div class="md:w-4/5 ml-auto mr-10 w-full">
                  <select @change="getMake" v-model="$store.state.manualMark" name="manualMark"
                    style="box-shadow: 1px 1px 1px rgba(26,37,47,0.33);"
                    class="bg-white ml-auto mr-auto appearance-none border-2 border-gray-200 rounded w-4/5 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                    id="manualMark">
                    <option value="999" selected="selected" disabled> Selectionner une marque </option>
                    <option v-for="marque in $store.state.marques" :value="marque.id" :key="marque.id"> {{ marque.make }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="md:w-1/2 w-full mt-auto">
              <div class="md:flex md:items-center mb-6 mt-auto w-full">
                <div class="md:w-1/5  w-full">
                  <label class="md:w-full  font-bold md:text-left mr-auto mb-1 md:mb-0 pr-4"
                    style="text-shadow: 1px 1px 1px rgba(26,37,47,0.33);" for="manualModel">
                    Modèle
                  </label>
                </div>
                <div class="md:w-4/5 ml-auto mr-10  w-full">
                  <select @change="getCarburant" v-model="$store.state.selectedMake"
                    style="box-shadow: 1px 1px 1px rgba(26,37,47,0.33);" name="manualMake"
                    class="bg-white  appearance-none border-2 border-gray-200 rounded w-4/5 ml-auto mr-auto py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                    id="manualModel">
                    <option selected disabled value="999">Séléctionner un modèle</option>
                    <option v-for="make in $store.state.makes" :key="make.id" :value="make.id"> {{ make.model }}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div class="flex flex-wrap w-3/4 mx-auto">
            <div class="md:w-1/2 w-full mt-auto">
              <div class="md:flex md:items-center mb-6 mt-auto w-full">
                <div class="md:w-1/5 w-full">
                  <label class="md:w-full w-full  font-bold md:text-left mb-1 md:mb-0 pr-4"
                    style="text-shadow: 1px 1px 1px rgba(26,37,47,0.33);" for="manualEnergie">
                    Energie
                  </label>
                </div>
                <div class="md:w-4/5 ml-auto mr-10 w-full">
                  <select v-model="$store.state.selectedCarburant" name="manualEnergie"
                    style="box-shadow: 1px 1px 1px rgba(26,37,47,0.33);"
                    class="bg-white appearance-none border-2 border-gray-200 rounded w-4/5 ml-auto mr-auto py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                    id="manualEnergie">
                    <option selected disabled value="999">Séléctionner le carburant</option>
                    <option v-for="carbu in $store.state.carburant" :key="carbu.id" :value="carbu.id">{{ carbu.fuel }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div class="md:w-1/2 w-full mt-auto">
              <div class="md:flex md:items-center mb-6 mt-auto w-full">
                <div class="md:w-1/5 w-full">
                  <label class="md:w-full w-full  font-bold md:text-left mb-1 md:mb-0 pr-4"
                    style="text-shadow: 1px 1px 1px rgba(26,37,47,0.33);" for="manualFinition">
                    Finition
                  </label>
                </div>
                <div class="md:w-4/5 ml-auto mr-10 w-full">
                  <select @change="goToVersion" v-model="$store.state.selectedTrim"
                    style="box-shadow: 1px 1px 1px rgba(26,37,47,0.33);" name="manualFinition"
                    class="bg-white appearance-none border-2 border-gray-200 rounded w-4/5 ml-auto mr-auto py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                    id="manualFinition">
                    <option selected disabled value="999">Séléctionner la version</option>
                    <option v-for="trimline in $store.state.trim" :key="trimline.id" :value="trimline.id">
                      {{ trimline.trim }}</option>
                  </select>
                </div>
              </div>
            </div>

          </div>
          <div class="flexRow">
            <button class="button1 ml-auto mr-auto " style="vertical-align:middle; width:auto!important"
              @click="goToVersion"><span>Valider </span></button>
          </div>
        </div>
      </div>
      <div id="zImat" class="w-full bg-white absolute left-0 top-0   h-full "
        style="width:100%;transform:rotate3d(0, 1, 0, 0deg); height:85vh;">
        <div class="relative mb-0 ml-auto mr-auto" style="width:100%;height:65px;">
          <img class="ml-auto mr-auto mt-4 mb-5" src="../assets/imat.png" alt=""
            style="max-width:400px;min-width:50%; height:auto;width:85vw">
          <input id="plaque" v-model="$store.state.imatInput" v-on:keydown="imatDataInput"
            class="absolute bg-black  text-red-500 z-50 text-center p-0 top-0 mb-10 ml-auto mr-auto" type="text"
            style="top:0px;!important;left:0px!important;font-size: 6vw; border:none;width:100%;height: auto!important;"
            placeholder="AB-123-CD" maxlength="9">
          <button class="button1 ml-auto mr-auto " style="vertical-align:middle; width:auto!important"
            @click="goNextMobileImat"><span>Valider </span></button>
          <p class="mt-5 ml-auto mr-auto mb-2 md:w-1/2 w-full" style=""> Vous allez recevoir<strong> votre estimation de
              reprise gratuitement</strong> par mail en moins de<strong> 2 minutes</strong>. <br /> Laissez-vous guider
            par notre questionnnaire, si vous avez besoin d'aide, <strong>merci de nous contacter par téléphone ou notre
              messagerie instantanée.</strong></p>
          <h1 id="btnManualSearch"
            class=" mt-5 text-center border border-black rounded-md w-full md:w-1/2 cursor-pointer ml-auto mr-auto"
            @click="btnManualSearch" style="font-family: Calibri; font-size: 20px;">Recherche manuelle</h1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import jQuery from 'jquery'
const $ = jQuery
export default {
  name: 'Step0.vue',
  data () {
    return {
      year: [],
      days: [],
      selectedYear: '2022',
      selectedMonth: '01',
      selectedDay: '31',
      errorCatch1: '',
      showButton: false,
      manualSearchButton: false
    }
  },
  created () {
    this.axios.get('https://api.db-ip.com/v2/free/self')
      .then((response) => {
        this.$store.state.sharePoint.city = response.data.city
        this.$store.state.sharePoint.continentCode = response.data.continentCode
        this.$store.state.sharePoint.continentName = response.data.continentName
        this.$store.state.sharePoint.countryCode = response.data.countryCode
        this.$store.state.sharePoint.countryName = response.data.countryName
        this.$store.state.sharePoint.region = response.data.stateProv
        this.$store.state.sharePoint.ipAddress = response.data.ipAddress
      })
  },
  beforeMount () {
    for (let i = 2022; i > 1900; i--) {
      if (i > 1900) this.year.push(i)
    }
    for (let i = 30; i > 0; i--) {
      if (i > 0) this.days.push(i)
    }
  },
  methods: {
    showError (title, msg, img, setImg) {
      this.$store.state.errorMsg = 'Erreur détéctée'
      this.$store.state.errorDescription = ''

      $('#errorModalId').animate({
        top: '300px'
      }, 1000, 'linear', () => {
        this.$store.state.image = setImg
        this.$store.state.imageUrl = img
        this.$store.state.errorMsg = title
        this.$store.state.errorDescription = msg
      })
    },
    closeError () {
      $('#errorModalId').animate({
        top: '-600px'
      }, 1000, 'linear', () => {
        this.$store.state.errorMsg = ''
        this.$store.state.errorDescription = ''
        this.$store.state.image = false
        this.$store.state.imageUrl = ''
      })
    },
    btnImatSearch () {
      $('#btnManualSearch').removeClass('selected')
      $('#btnManualSearch').addClass('text-black')
      $('#btnManualSearch').removeClass('text-white')
      $('#btnImatSearch').addClass('selected')
      $('#btnImatSearch').addClass('text-white')
      $('#manualSearchDiv').removeClass('rotateToManual')
      $('#manualSearchDiv').addClass('rotateToImat')
      setTimeout(() => {
        $('#btnManualSearch').removeClass('unselected')
        $('#zImat').removeClass('unselected')
        $('#zImat').css('z-index', 20)
        $('#zManual').css('z-index', 10)
      }, 620)
    },
    btnManualSearch () {
      $('#btnManualSearch').addClass('selected')
      $('#btnManualSearch').removeClass('text-black')
      $('#btnManualSearch').addClass('text-white')
      $('#btnImatSearch').removeClass('selected')
      $('#btnImatSearch').removeClass('text-white')
      $('#btnImatSearch').addClass('text-black')
      $('#manualSearchDiv').addClass('rotateToManual')
      $('#manualSearchDiv').removeClass('rotateToImat')
      setTimeout(() => {
        $('#btnManualSearch').addClass('unselected')
        $('#zImat').addClass('unselected')
        $('#zImat').css('z-index', 10)
        $('#zManual').css('z-index', 20)
      }, 620)
    },

    imatDataInput (event) {
      if (window.matchMedia('(min-width: 500px)').matches) {
        window.addEventListener('keyup', e => {
          const regex = /[a-zA-Z]{2}[0-9]{3}[a-zA-Z]{2}|[a-zA-Z]{2}[-]{1}[0-9]{3}[-]{1}[a-zA-Z]{2}/g
          if (e.keyCode === 8) this.$store.state.imatInput = ''
          if (this.$store.state.imatInput.length > 8 && regex.test(this.$store.state.imatInput)) {
            this.$store.state.imatSearchInput = ''
            this.$store.state.imatSearchInput = this.$store.state.imatInput.slice(0, 2) + this.$store.state.imatInput.slice(3, 6) + this.$store.state.imatInput.slice(7, 9)
            this.$store.commit('setImatLink', this.$store.state.imatSearchInput, 'imatSearch', 1)
            this.$store.state.loading = true
            this.$store.state.imatInput = ''
            this.showButton = false
          }
          if (this.$store.state.imatInput.length > 6 && regex.test(this.$store.state.imatInput)) {
            console.log('success')
            this.showButton = true
            this.$store.state.imatSearchInput = ''
            this.$store.state.imatSearchInput = this.$store.state.imatInput
            this.$store.commit('setImatLink', this.$store.state.imatSearchInput, 'imatSearch', 1)
            this.$store.state.loading = true
            this.$store.state.imatInput = ''
          }
        })

        this.$store.state.imatInput = this.$store.state.imatInput.toUpperCase()
        if (this.$store.state.imatInput.length === 2 || this.$store.state.imatInput.length === 6) {
          this.$store.state.imatInput += '-'
        }
      }
    },
    goNextMobileImat () {
      const regex = /[a-zA-Z]{2}[0-9]{3}[a-zA-Z]{2}|[a-zA-Z]{2}[-]{1}[0-9]{3}[-]{1}[a-zA-Z]{2}/g
      if (regex.test(this.$store.state.imatInput.toString())) {
        console.log('success')
        this.showButton = true
        this.$store.state.imatSearchInput = ''
        this.$store.state.imatSearchInput = this.$store.state.imatInput
        this.$store.commit('setImatLink', this.$store.state.imatSearchInput, 'imatSearch', 1)
        this.$store.state.loading = true
        this.$store.state.imatInput = ''
      } else {
        this.showError('Erreur', "Format de plaque d'immatriculation incorrecte le format attendu est aa123bb ou aa-123-bb")
      }
    },
    getMarque () {
      this.$store.state.trademodel.car.putIntoService = this.selectedYear + '-' + this.selectedMonth + '-01'
      this.$store.state.loading = true
      this.$store.state.marques = []
      this.$store.state.manualMark = []
      this.$store.state.selectedMarqueArray = []
      this.$store.state.make = []
      this.$store.state.carburant = []
      this.$store.state.selectedCarburant = []
      this.$store.state.trim = []
      this.$store.state.selectedTrim = []

      setTimeout(() => {
        const config = {
          headers: {
            Authorization: 'Bearer ' + this.$store.state.token
          }
        }
        this.axios.get(`https://configurator-api.openflex.eu/configurators/uc/makes?putIntoService=${this.$store.state.trademodel.car.putIntoService}&extend=true`, config)
          .then((response) => {
            this.$store.state.marques = response.data.items
            console.log(this.$store.state.marques)
            this.$store.state.manualMark = 999
            this.$store.state.trademodel.car.make = this.$store.state.marques[0].make
            this.$store.state.trademodel.car.makeId = this.$store.state.marques[0].id
            this.$store.state.loading = false
            console.log('Loading = false')
            this.getMake()
          }).catch((err, status, result) => {
            console.log(err, status)
            this.$store.state.loading = false
          })
      }, 500)
    },
    getMake () { // model
      const config = {
        headers: {
          Authorization: 'Bearer ' + this.$store.state.token
        }
      }
      this.$store.state.loading = true
      setTimeout(() => {
        this.axios.get(`https://configurator-api.openflex.eu/configurators/uc/makes/${this.$store.state.manualMark}/models?extend=true`, config)
          .then((response) => {
            this.$store.state.makes = response.data.items
            this.$store.state.selectedMake = 999
            if (this.$store.state.manualMark !== this.$store.state.marques[0].id) {
              this.$store.state.selectedMarqueArray = this.$store.state.marques.find(element => element.id === this.$store.state.manualMark)
              this.$store.state.trademodel.car.make = this.$store.state.manualMark.make
              this.$store.state.trademodel.car.makeId = this.$store.state.manualMark.id
            } else {
              this.$store.state.manualMark = this.$store.state.marques[0].id
              this.$store.state.trademodel.car.make = this.$store.state.marques[0].make
              this.$store.state.trademodel.car.makeId = this.$store.state.marques[0].id
              this.getCarburant()
            }
            this.$store.state.loading = false
          }).catch((err, status, result) => {
            console.log(err, status)
            this.$store.state.loading = false
          })
      }, 250)
    },
    getCarburant () {
      const config = {
        headers: {
          Authorization: 'Bearer ' + this.$store.state.token
        }
      }
      this.$store.state.loading = true
      setTimeout(() => {
        this.axios.get(`https://configurator-api.openflex.eu/configurators/uc/makes/${this.$store.state.manualMark}/models/${this.$store.state.selectedMake}/fuels?extend=true`, config)
          .then((test) => {
            this.$store.state.carburant = test.data.items
            this.$store.state.selectedCarburant = 999
          }).catch((err, status, result) => {
            console.log(err, status)
          })
      }, 250)
      setTimeout(() => {
        this.axios.get(`https://configurator-api.openflex.eu/configurators/uc/makes/${this.$store.state.manualMark}/models/${this.$store.state.selectedMake}/trims`, config)
          .then((trim) => {
            this.$store.state.trim = trim.data.items
            if (this.$store.state.trim.length > 0) {
              this.$store.state.selectedTrim = 999
              this.$store.state.trademodel.car.trim = this.$store.state.trim[0].trim
              this.$store.state.loading = false
              this.$store.state.versionState = true
            } else {
              this.showError('Erreur', "Ce modele est unique ou ne peut être retrouver dans l'argus")
              this.$store.state.selectedTrim = ''
              this.$store.state.versionState = false

              this.$store.state.loading = false
            }
          }).catch((err, status, result) => {
            console.log(err, status)
            this.$store.state.loading = false
          })
      }, 500)
    },
    goToVersion () {
      if (this.$store.state.versionState) {
        this.$store.state.trademodel.car.fuelId = this.$store.state.selectedCarburant.id
        this.$store.state.trademodel.car.fuel = this.$store.state.selectedCarburant.fuel
        this.$store.state.trademodel.car.trim = this.$store.state.selectedTrim.trim
        const config = {
          headers: {
            Authorization: 'Bearer ' + this.$store.state.token
          }

        }
        this.axios.get(`https://configurator-api.openflex.eu/configurators/uc/makes/${this.$store.state.manualMark}/models/${this.$store.state.selectedMake}/versions`, config)
          .then((response) => {
            this.$store.state.manualSearch = response.data.items
            this.$store.state.stept = 0.5
            this.$store.state.manual = true
            this.$store.state.maxStept = 0.5
          }).catch((err, status, result) => {
            console.log(err, status)
          })
      }
    }
  }
}
</script>

<style scoped>p {
  position: relative;
  background-color: #cce9ed;
  padding: 1.125em 1.5em;
  font-size: 12px;
  text-align: left;
  border-radius: 3px;
}

p::before {
  content: "";
  position: absolute;

  width: 0;
  height: 0;
  bottom: 100%;
  left: 1.5em;
  border: 0.75rem solid transparent;
  border-top: none;
  border-bottom-color: #cce9ed;
  filter: drop-shadow(0 -0.0625rem 0.0625rem rgba(0, 0, 0, 0.1));
}</style>
