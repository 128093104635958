import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    smsSent: false,
    userData: {
      phone_number: "+",
      first_name: "",
      last_name: "",
      email: "",
      cp: ""
    },
    tentatives: 3,
    validationSms: false,
    otpToken: '',
    step1: 'Commencer',
    step2: 'Votre véhicule',
    step3: 'Informations',
    step4: 'Votre estimation',
    counters: [{id: 0}],
    essaiSms: 2,
    zipCode: '',
    loading: false,
    token: '',
    imatInput: '',
    dateOptions: {weekday: 'long', year: 'numeric', month: 'long', day: '2-digit'},
    getToken: 'https://identity-api.openflex.eu/auth/campaigns/sign-in',
    getCampagnData: 'https://identity-api.openflex.eu/campaigns/current/context',
    getCampagn: 'https://selling-api.openflex.eu/campaigns/current',
    getFournisseurConfig: 'https://configurator-api.openflex.eu/provider-configurations/current',
    configFournisseurData: '',
    getOppoprtunitySettings: 'https://selling-api.openflex.eu/opportunities/settings',
    getPricePolicies1: 'https://scoring-api.openflex.eu/pricing-policies/current/1',
    getPricePolicies2: 'https://scoring-api.openflex.eu/pricing-policies/current/2',
    imatSearch: 'https://configurator-api.openflex.eu/configurators/numberplate/',
    getCatalogById: 'https://configurator-api.openflex.eu/configurators/cars/',
    getPaint: 'https://configurator-api.openflex.eu/configurators/cars/',
    pricingAutovalidation: '',
    putIntoServiceYear: '',
    pricingAutovalidationData: '',
    estimatedDamageId: '1',
    automaticValidationId: '',
    policyId: '',
    errorCatch1: '',
    minimumTradeInPrice: '',
    maximumAgeTraedInCars: '',
    campagnData: false,
    campagnManual: false,
    campagnImat: true,
    imat: '',
    referentialCarId: '0',
    vin: '',
    stept: 0,
    imatLink: '',
    dmgSectionLabel: '',
    dmgSectionKey: '',
    imatSearchInput: '',
    make: '',
    subId: '',
    trimline: '',
    catalogDate: '',
    equipment: {},
    model: '',
    design: {},
    catalog: {modelCatalogBegin: '0', modelCatalogEnd: '0'},
    colors: {},
    interieur: {},
    imatData: [],
    equipements: [],
    defectSection: {},
    addedEquipement: [],
    defect: [],
    defectArray: [],
    pointOfSale: [],
    cellphone: '',
    colorInt: {},
    colorXt: {},
    typeInt: {},
    typeXt: {},
    modelChoose: {},
    catalogModel: {},
    groupe: [],
    equipementList: [],
    damageSectionList: [],
    manualSearchButton: false,
    trueLabel: '',
    estimatedDamage: [],
    rearTyreWear: 0,
    frontTyreWear: 0,
    carState: 'static/3p/leftToFront.gif',
    carPosition: 'front',
    selectedLabel: '',
    verification: false,
    selectedKey: '',
    manual:false,
    nbDamage: 'est en bon état.',
    smsCode: '',
    otp: '',
    doubleKey: false,
    malusImport: true,
    peintureG: true,
    sellerieG: true,
    mileage: '',
    putIntoService: '',
    firstHand: false,
    willBuy: false,
    imported: false,
    upToDateMaintenanceBook: true,
    marketingConsent: false,
    thirdConsent: false,
    campagnId: "",
    sellingPrice: 0,
    estimation: true,
    sellingStatus: 0,
    errors: [],
    name: null,
    age: null,
    movie: null,
    requestId: '',
    maxStept: 0,
    manualMark: [1],
    estimColor: '',
    marques: [],
    makes: [],
    selectedMake: [],
    image: false,
    imageUrl: '',
    errorId: '',
    options: false,
    declareDmg: false,
    phoneNumber: '',
    carburant: [],
    selectedCarburant: [],
    trim: [],
    selectedTrim: [],
    manualSearch: [],
    selectedMakeArray: [],
    selectedMarqueArray: [],
    errorDescription: 'Test',
    errorMsg: 'Test',
    errorModal: false,
    versionState: false,
    rotateDeg: 0,
    sharePoint: {
      "maxStep": 0,
      "step": 0,
      "ipAddress": '',
      "continentCode": '',
      "continentName": '',
      "countryCode": '',
      "countryName": '',
      "region": '',
      "city": '',
      "id": "",
      "sex": "",
      "nom": "",
      "prenom": "",
      "zipCode": "",
      "mail": "",
      "phone": '+33',
      "marque": "",
      "model": "",
      "motor": "",
      "finition": "",
      "boite": "",
      "porte": "",
      "couleur": "",
      "energie": "",
      "version": "",
      "generic": "",
      "mise_en_service": "",
      "immat": "",
      "km": 0,
      "score": 0,
      "couleur_interieur": "",
      "premiere_main": false,
      "prix_estime": 0,
      "prix_ajuste": 0,
      "prix_propose": 0,
      "places": 0,
      "chassis": "",
      "transmission": "",
      "images": [],
      "status": 0,
      "tryByNumber": 0,
    },
    trademodel: {
      "customer": {
        "type": 1, // ok
        "civility": 2, // ok
        "name": "", // ok
        "firstname": "",  // ok
        "zipCode": "",  // ok
        "cellphone": "",  // ok
        "cellphonePrefixCode": "+33",  // ok
        "email": "",  // ok
        "marketingConsent": true,  // ok
        "thirdConsent": false  // ok
      },
      "car": {
        "carId": null, // ok
        "configuration": null, // ok
        "referentialCarId": "", // ok
        "typeId": "",
        "type": "Véhicule particulier", // ok
        "makeId": "null",
        "make": "null",
        "modelGroupId": "null",
        "modelGroup": "null",
        "generation": "",
        "modelId": "",
        "model": "",
        "trim": "",
        "version": "",
        "fuelId": "",
        "fuel": "",
        "genericFuelId": null,
        "genericFuel": "",
        "transmissionTypeId": "",
        "transmissionType": "",
        "genericTransmissionTypeId": null,
        "genericTransmissionType": "",
        "bodyId": "",
        "body": "",
        "genericBodyId": null,
        "genericBody": "",
        "segmentationId": "",
        "segmentation": "",
        "driveTypeId": "",
        "driveType": "",
        "genericDriveTypeId": null,
        "genericDriveType": "",
        "emissionId": "",
        "emission": "",
        "seats": null,
        "gears": null,
        "doors": null,
        "valves": null,
        "torque": null,
        "displacement": null,
        "cylinders": null,
        "cylindersTypeId": "",
        "cylindersType": "",
        "horsepower": null,
        "taxHorsepower": null,
        "kilowatt": null,
        "co2Emission": null,
        "mixedConsumption": null,
        "urbanConsumption": null,
        "extraUrbanConsumption": null,
        "length": null,
        "width": null,
        "height": null,
        "totalWeight": null,
        "payload": null,
        "rooflad": null,
        "curbWeight": null,
        "topSpeed": null,
        "acceleration": null,
        "trunkMinimumCapacity": null,
        "trunkMaximumCapacity": null,
        "modelCatalogBegin": "null",
        "modelCatalogEnd": "null",
        "catalogBeginPrice": "null",
        "catalogEndPrice": "null",
        "catalogExclVatPrice": null,
        "catalogInclVatPrice": null,
        "catalogTax": null, // ok
        "catalogPriceOverloaded": false,
        "frontTyreWidth": "null",
        "frontTyreHeight": "null",
        "frontTyreSpeedIndex": "0",
        "frontTyreDiameter": "0",
        "frontTyreWear": 0,
        "rearTyreWidth": "0",
        "rearTyreHeight": "0",
        "rearTyreSpeedIndex": "0",
        "rearTyreDiameter": "0",
        "rearTyreWear": 1000,
        "internalColorCode": null,
        "internalColorWording": "0",
        "internalColorReference": "0",
        "externalColorCode": null,
        "externalColorWording": "Noir",
        "externalColorReference": "0",
        "putIntoService": "0", // ok
        "origin": 1,
        "mileage": 0, // ok
        "mileageDate": Date.now(), // ok
        "guaranteedMileage": false, // ok
        "forecastedDate": Date.now(), // ok
        "forecastedMileage": 60940,
        "numberplate": null,
        "codeFactory": null,
        "tcenum": "n.c.",
        "referentialPicture": "https://filerender-api.openflex-preprod.eu/files/images/hwpUqgUBa6JLI6cPklJvNsraV_6Z8YN%2Bjiq3IaKS4ARdvJdMAqc75AKBYDACl5QOLn5pNjRBja8E7CIg2w6m0gPOs7qgcgTRJA%3D%3D",
        "firstHand": true, // ok
        "upToDateMaintenanceBook": true, // ok
        "import": false, // ok
        "financing": 1,
        "applyMaintenanceCosts": true,
        "pictures": [],
        "referentialPollutionClassification": "Euro 6",
        "saddleries": [],
        "paintings": [],
        "equipments": [],
        "estimatedDamages": {}
      },

      "furtherInformations": [

        {

          "key": "Envisagez-vous l'acquisition d'un nouveau véhicule ?",

          "value": "Oui, d'occasion"

        }

      ]

    },
    vonage: 'EEV4TY3wjB8nJeZNJZAAhpQnnagM5SFO',
    trademodelSave: {
      "customer": {
        "type": 1, // ok
        "civility": 2, // ok
        "name": "", // ok
        "firstname": "",  // ok
        "zipCode": "",  // ok
        "cellphone": "",  // ok
        "cellphonePrefixCode": "+33",  // ok
        "email": "",  // ok
        "marketingConsent": true,  // ok
        "thirdConsent": false  // ok
      },
      "car": {
        "carId": 176331, // ok
        "configuration": 3, // ok
        "referentialCarId": "176331", // ok
        "typeId": "10",
        "type": "Véhicule particulier", // ok
        "makeId": "21",
        "make": "MERCEDES",
        "modelGroupId": "3046",
        "modelGroup": "CLASSE A",
        "generation": "CLASSE A III",
        "modelId": "1344",
        "model": "CLASSE A",
        "trim": "Fascination",
        "version": "Classe A 180",
        "fuelId": "00100001",
        "fuel": "Essence sans plomb",
        "genericFuelId": 1,
        "genericFuel": "Essence",
        "transmissionTypeId": "00180001",
        "transmissionType": "Boîte manuelle",
        "genericTransmissionTypeId": 1,
        "genericTransmissionType": "Boîte manuelle",
        "bodyId": "00010060",
        "body": "Berline",
        "genericBodyId": 11,
        "genericBody": "Berline",
        "segmentationId": "00030004",
        "segmentation": "Moyenne inférieure",
        "driveTypeId": "00050001",
        "driveType": "Traction avant",
        "genericDriveTypeId": 1,
        "genericDriveType": "2 roues motrices",
        "emissionId": "00170007",
        "emission": "G-Kat",
        "seats": 5,
        "gears": 6,
        "doors": 5,
        "valves": 4,
        "torque": 200,
        "displacement": 1595,
        "cylinders": 4,
        "cylindersTypeId": "00080001",
        "cylindersType": "Ligne",
        "horsepower": 122,
        "taxHorsepower": 7,
        "kilowatt": 90,
        "co2Emission": 131,
        "mixedConsumption": 5.6,
        "urbanConsumption": 7.6,
        "extraUrbanConsumption": 4.5,
        "length": 4299,
        "width": 1848,
        "height": 1433,
        "totalWeight": 1935,
        "payload": 565,
        "rooflad": 0,   // UNDEFINED
        "curbWeight": 1370,
        "topSpeed": 202,
        "acceleration": 8.9,
        "trunkMinimumCapacity": 341,
        "trunkMaximumCapacity": 1157,
        "modelCatalogBegin": "2015-07-01T02:00:00+02:00",
        "modelCatalogEnd": "2018-03-01T01:00:00+01:00",
        "catalogBeginPrice": "2016-04-14T02:00:00+02:00",
        "catalogEndPrice": "2017-01-08T01:00:00+01:00",
        "catalogExclVatPrice": 28250,
        "catalogInclVatPrice": 33900,
        "catalogTax": 20, // ok
        "catalogPriceOverloaded": false,
        "frontTyreWidth": "225",
        "frontTyreHeight": "40",
        "frontTyreSpeedIndex": "R",
        "frontTyreDiameter": "18",
        "frontTyreWear": 50,
        "rearTyreWidth": "225",
        "rearTyreHeight": "40",
        "rearTyreSpeedIndex": "R",
        "rearTyreDiameter": "18",
        "rearTyreWear": 100,
        "internalColorCode": null,
        "internalColorWording": "Noir",
        "internalColorReference": "000000",
        "externalColorCode": null,
        "externalColorWording": "Noir",
        "externalColorReference": "000000",
        "putIntoService": "", // ok
        "origin": 1,
        "mileage": 60000, // ok
        "mileageDate": Date.now(), // ok
        "guaranteedMileage": false, // ok
        "forecastedDate": Date.now(), // ok
        "forecastedMileage": 60940,
        "numberplate": null,
        "codeFactory": null,
        "tcenum": "n.c.",
        "referentialPicture": "https://filerender-api.openflex-preprod.eu/files/images/hwpUqgUBa6JLI6cPklJvNsraV_6Z8YN%2Bjiq3IaKS4ARdvJdMAqc75AKBYDACl5QOLn5pNjRBja8E7CIg2w6m0gPOs7qgcgTRJA%3D%3D",
        "firstHand": true, // ok
        "upToDateMaintenanceBook": true, // ok
        "import": false, // ok
        "financing": 1,
        "applyMaintenanceCosts": true,
        "pictures": [],
        "referentialPollutionClassification": "Euro 6",
        "saddleries": [
          {
            "id": 209953613,
            "code": "135721",
            "equipment": "Garnitures intérieures en microfibre DINAMICA/similicuir ARTICO",
            "inclVatPrice": 0,
            "exclVatPrice": 0,
            "tax": 20,
            "serial": true,
            "manufacturer": null,
            "label": "Garnitures intérieures en microfibre DINAMICA/similicuir ARTICO",
            "price": 0,
            "referentialId": "209953613",
            "wording": "Garnitures intérieures en microfibre DINAMICA/similicuir ARTICO"
          },
          {
            "id": 209953654,
            "code": "140063",
            "equipment": "Pack AMG Line Intérieur",
            "inclVatPrice": 0,
            "exclVatPrice": 0,
            "tax": 20,
            "serial": true,
            "manufacturer": null,
            "label": "Pack AMG Line Intérieur",
            "price": 0,
            "referentialId": "209953654",
            "wording": "Pack AMG Line Intérieur"
          }
        ],
        "paintings": [
          {
            "id": 209953675,
            "code": null,
            "equipment": "Peinture métallisée",
            "inclVatPrice": 850,
            "exclVatPrice": 708.33,
            "tax": 20,
            "serial": false,
            "manufacturer": null,
            "label": "Peinture métallisée",
            "price": 850,
            "referentialId": "209953675",
            "wording": "Peinture métallisée"
          }
        ],
        "equipments": [],

        "estimatedDamages": {
          "0": {
            "tax": 20,
            "type": 1
          },
          "1": {
            "tax": 20,
            "type": 2,
            "tyreSize": "18",
            "frontTyreWear": 50
          },
          "2": {
            "tax": 20,
            "type": 2,
            "tyreSize": "18",
            "rearTyreWear": 100
          },
          "3": {
            "section": 20,
            "defectId": 95,
            "type": 3,
            "tax": 20,
            "wording": "Bosselé(e)",
            "privateExclVatPrice": 75,
            "professionalExclVatPrice": 75,
            "pictures": []
          },
          "4": {
            "section": 4,
            "defectId": 90,
            "type": 3,
            "tax": 20,
            "wording": "À remplacer",
            "privateExclVatPrice": 600,
            "professionalExclVatPrice": 600,
            "pictures": []
          },
          "5": {
            "section": 11,
            "defectId": 97,
            "type": 3,
            "tax": 20,
            "wording": "Peinture",
            "privateExclVatPrice": 100,
            "professionalExclVatPrice": 100,
            "pictures": []
          },
          "6": {
            "section": 29,
            "defectId": 98,
            "type": 3,
            "tax": 20,
            "wording": "Absence du double des clès",
            "privateExclVatPrice": 200,
            "professionalExclVatPrice": 200,
            "pictures": []
          }
        }
      },

      "furtherInformations": [

        {

          "key": "Envisagez-vous l'acquisition d'un nouveau véhicule ?",

          "value": "Oui, d'occasion"

        }

      ]

    }
  },
  mutations: {
    setImatLink() {
      const link = 'https://configurator-api.openflex.eu/configurators/numberplate/' + this.state.imatSearchInput + '?context=2&vinBillable=false&total=false'
      this.commit('getData', link)
      this.state.stept = 1
      console.log(this.state.stept, 'this step is 1')
      this.state.sharePoint.step = 1
      this.state.manual = false

      if (this.state.maxStept < this.state.stept) this.state.maxStept = 1
    },
    setColorLink(url, to, step) {
      this.state.trademodel.putIntoService =  `${this.state.catalog.modelCatalogBegin.slice(8,10)}/${this.state.catalog.modelCatalogBegin.slice(5,7)}/${this.state.catalog.modelCatalogBegin.slice(0,4)}`
      this.state.loading = true
      const link = 'https://configurator-api.openflex.eu/configurators/cars/' + this.state.referentialCarId + '/paints'
      this.state.stept = 3
      this.state.sharePoint.step = 3
      if (this.state.maxStept < this.state.stept) this.state.maxStept = 3
      this.commit('getData',link)
      this.commit('setInsideColorLink')
      this.commit('setSellerieLink')
    },
    setInsideColorLink(url, to, step) {
      const link = 'https://configurator-api.openflex.eu/configurators/uc/makes/' + this.state.make + '/models/' + this.state.model + '/colors'
      this.commit('getData',link)


    },
    setSellerieLink(url, to, step) {
      const link = 'https://configurator-api.openflex.eu/configurators/cars/' + this.state.referentialCarId + '/saddleries'

       this.commit('getData',link)
    },
    setEquipementsLink(url, to, step) {
      this.state.loading = true
      const link = 'https://configurator-api.openflex.eu/configurators/cars/' + this.state.referentialCarId + '/equipments?serial=true&group=true'
      this.state.stept = 6
      this.state.sharePoint.step = 6
      if (this.state.maxStept < this.state.stept) this.state.maxStept = 6

      this.commit('getData',link)
    },
    getCatalog( payload ) {
      const link = this.state.getCatalogById + this.state.referentialCarId
      this.commit('getData', link)
    },
    getData(idk, url, saveTo, step) {
      setTimeout(() => {
        const config = {
          headers: {
            'Authorization': 'Bearer ' + this.state.token
          }
        }
        let self = this;
        axios.get(url, config).then(response => {
          this.state.loading = false
          this.state.defectSection = response.data
          this.state.errorCatch1 = ''

          if (url === this.getPricePolicies1 || url === this.getPricePolicies2) {
            this.state.estimatedDamageId = response.data.pricingPolicy.estimatedDamageId
            this.state.automaticValidationId = response.data.pricingPolicy.automaticValidationId
            this.state.policyId = response.data.pricingPolicy.id
            this.state.pricingAutovalidation = 'https://scoring-api.openflex.eu/pricing-policies/' + this.state.policyId + '/automatic-validations/' + this.state.automaticValidationId
          }
          if (url === 'https://selling-api.openflex.eu/references/damages-sections') {
            this.state.damageSectionList = response.data
          }
          if (url === this.pricingAutovalidation) {
            this.state.minimumTradeInPrice = response.data.automaticValidation.minimumTradeInPrice
            this.state.maximumAgeTraedInCars = response.data.automaticValidation.maximumAgeTraedInCars
          }
          if (url.indexOf('/estimated-damages/') !== -1) {
            this.state.defectSection = response.data
          }
          if (url.indexOf('https://configurator-api.openflex.eu/configurators/numberplate/') !== -1) {
            this.state.imatData = response.data.items
            this.state.imat = this.state.imatData[0].numberplate
            this.state.stept = 1
            console.log(this.state.stept  )
          }
          if (url.indexOf(this.state.getCatalogById + this.state.referentialCarId) !== -1) {
            if (response.data.vehicleInformations) {
              this.state.catalog = response.data.vehicleInformations
            }
            /*if (response.data.vehicleInformations) console.log('catalog', this.state.catalog)*/
            if (this.state.catalog.trimline) this.state.trimline = this.state.catalog.trimline
            if (this.state.catalog.model) this.state.model = this.state.catalog.model
            if (this.state.catalog.make) this.state.make = this.state.catalog.make
          }
          if (url.indexOf('https://configurator-api.openflex.eu/configurators/uc/makes/' + this.state.make + '/models/' + this.state.model + '/colors') !== -1){
            this.state.stept = 3
            this.state.sharePoint.step = 3
            if (this.state.maxStept < this.state.stept) this.state.maxStept = 3
            this.state.colors = response.data
          }
          if (url.indexOf('https://configurator-api.openflex.eu/configurators/cars/' + this.state.referentialCarId + '/paints') !== -1){
            this.state.stept = 3
            this.state.sharePoint.step = 3
            if (this.state.maxStept < this.state.stept) this.state.maxStept = 3
            this.state.design = response.data
          }

          if (url.indexOf('https://configurator-api.openflex.eu/configurators/cars/' + this.state.referentialCarId + '/saddleries') !== -1){
            this.state.stept = 3
            this.state.sharePoint.step = 3
            if (this.state.maxStept < this.state.stept) this.state.maxStept = 3
            this.state.interieur = response.data
          }
          if (url.indexOf('https://configurator-api.openflex.eu/configurators/cars/' + this.state.referentialCarId + '/equipments?serial=true&group=true') !== -1){
            this.state.equipment = response.data
            // this.commit('equipmentList', this.state.equipment)
            const items = this.state.equipment.equipments
            const Group = this.state.equipment.equipmentGroups
            items.forEach((item) => {
              this.state.equipements.push(item)
            })
            Group.forEach((group) => {
              const sub = group.equipmentSubGroups
              sub.forEach((e) => {
                this.state.equipements.forEach((equip) => {
                  if (equip.equipmentSubGroups[0] === e.id) {
                    Object.assign(e, { [equip.code]: equip })
                  }
                })
              })
            })
          }
          if (this.state.step === 5) {

          }
          if (step === 6) {
            this.state.equipment = response.data
            this.commit('equipmentList', this.state.equipment)
          }
        }).catch(function (error) {
          if (error.response) {
            self.state.loading = false
            self.commit('newEstimation')
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            self.state.loading = false
            self.commit('newEstimation')

            console.log(error.request);
          } else {
            console.log('Error', error.message);
            self.state.loading = false
            self.commit('newEstimation')
          }
          self.state.loading = false
          self.commit('newEstimation')
          console.log(error.config);
        });
      }, 400)
    },
    stepin (step, percent) {
      $('.progress').animate({
        width: `${percent}%`
      }, 1000)
      setTimeout(() => {
        $('#step' + step).hasClass('red') ? this.rtog(step) : this.gtor(step)
      }, 700)

      // this.stept = step
    },
    newEstimation () {
      this.state.stept = 0
      this.state.maxStept = 0
      this.state.trademodel = this.state.trademodelSave
      this.state.equipment = {}
      this.state.model = ''
      this.state.design = {}
      this.state.catalog = { modelCatalogBegin: '0', modelCatalogEnd: '0' }
      this.state.colors = {}
      this.state.interieur = {}
      this.state.imatData = []
      this.state.equipements = []
      this.state.defectSection = {}
      this.state.addedEquipement = []
      this.state.defect = []
      this.state.defectArray = []
      this.state.pointOfSale = []
      this.state.colorInt = {}
      this.state.colorXt = {}
      this.state.typeInt = {}
      this.state.typeXt = {}
      this.state.modelChoose = {}
      this.state.groupe = []
      this.state.equipementList = []
      this.state.damageSectionList = []
      this.state.trueLabel = ''
      this.state.estimatedDamage = []

    },

  },
  actions: {
  },
  modules: {
  }
})
