<template>
  <div id="Step6"  class="relative text-black ml-auto mr-auto" style="height:70vh; width:100%;">
    <div class="w-full mx-auto p-2 h-10/12 transition-all transition">
      <p style="font-size: 14px;" class="pb-5"> <strong>De quels équipements votre voiture dispose ?</strong></p>
      <div class="border  md:w-3/5 w-full ml-auto mr-auto border-gray-200 mb-5"></div>
      <div class="ml-auto mr-auto w-full md:w-3/5">
      <div class="relative w-3/5 flex-row text-left mx-auto pl-12 mt-1 mb-3 h-8 w-full validate3 font-bold textSm" style=" height: 20px; font-family: Calibri; margin-right:auto!important;line-height: 20px;" >  Cochez les options si besoin </div>
      </div>

      <vsa-list>
        <vsa-item  v-for="groupe in $store.state.equipment.equipmentGroups" :key="groupe.id">
          <vsa-heading :id="groupe.id">
            <div  class="w-full" style="font-size:12px!important;">{{ groupe.group }}</div>
          </vsa-heading>
          <vsa-content>
              <div :id="sub.id" v-for="sub in groupe.equipmentSubGroups">
                <div>{{ sub.subGroup }}</div>
                <p style="text-decoration: none!important" v-if="item.equipment" v-for="(item,index ) in sub" :key="index" :id="item.id" v-on:click="addEquipement(item.id,sub.id, $store.state.groupe.id, item)" :class="item.id" class="validate2 text-left m-1 p-0 text-black ellipsis w-full pl-12 h-auto relative cursor-pointer transition textSm" :data-text="item.equipment">{{item.equipment}}</p>

              </div>

    </vsa-content>
        </vsa-item>
      </vsa-list>

<!--      <div class="w-full md:w-3/5 w-full mx-auto pb-5">
        <div class="shadow-md">
          <div class="tab w-full overflow-hidden border-t relative cursor-pointer border-2 border-gray-200 bg-white mb-2 transition" style="font-family: Calibri; font-weight: bold;" @click="openEquipmentTab(groupe.id, groupe)" v-for="groupe in $store.state.equipment.equipmentGroups">
            <input class="absolute opacity-0 left-0 h-18 transition cursor-pointer" :id="groupe.id" type="radio" name="tabs2" @click="openEquipmentTab(groupe.id, groupe)" style="height:55px;">
            <label class="block p-2 leading-normal cursor-pointer transition textSm" @click="openEquipmentTab(groupe.id, groupe)" :id="groupe.id" >{{ groupe.group }}</label>
            <div class="tab-content overflow-hidden border-l-2 bg-gray-100 border-indigo-500 leading-normal transition" v-for="sub in $store.state.groupe.equipmentSubGroups">
              <div class="p-2 font-bold h-full m-1 pl-1 text-left transition" style="font-size:14px;">{{ sub.subGroup }}</div>
              <div v-if="item.equipment" v-for="(item,index ) in sub" :key="index" :id="item.id" v-on:click="addEquipement(item.id,sub.id, groupe.group.id, item)" :class="item.id" class="validate2 text-left m-1 p-0 text-black  w-full pl-12 h-auto relative cursor-pointer transition textSm">{{item.equipment}}</div>            </div>
          </div>
        </div>
      </div>-->


      <!-- <div class="w-full mt-4 h-full" style="display:flex;flex-flow:wrap;">
        <div class="flexCol md:w-5/12  w-full  bg-gray-100 rounded-md mt-2 p-2" style="min-height:70vh; max-height:70vh;">
          <p class="mb-3 text-gray-700 font-bold"> Catégories d'équipements :</p>
          <div  @click="openEquipmentTab(groupe.id, groupe)" v-for="groupe in $store.state.equipment.equipmentGroups" class=" p-1 w-full h-full text-black  cursor-pointer z-10 overflow-x-visible" style="display:inline;font-family: Calibri,serif;color:black!important;height:33px;overflow: hidden;">
            <div class="flexCol z-20 w-full">
              <div :id="groupe.id" class="w-full grp pl-4" style="text-align: left !important;">{{ groupe.group }} <span class="text-2xl" style="height:15px;">-</span> </div>
            </div>
          </div>
        </div>
        <div class="flexCol module module2   h-3/4  md:w-7/12 overflow-y-auto" style="min-height:70vh; max-height:70vh;overflow-y: auto;">
          <div class="mb-3 text-gray-700 font-bold"> Ajoutez les options de votre véhicule en commencant par choisir une catégorie dans la liste :</div>
          <div v-for="sub in $store.state.groupe.equipmentSubGroups" class="flexCol w-full transition-all transition" style=" height:auto;font-family: 'Calibri Light',serif;border-radius:0!important;">
            <div class="font-bold h-full m-1 pl-1 text-left" style="font-size:18px;">{{ sub.subGroup }}</div>
            <div v-if="item.equipment" v-for="(item,index ) in sub" :key="index" :id="item.id" v-on:click="addEquipement(item.id,sub.id, $store.state.groupe.id, item)" :class="item.id" class="validate2 text-left m-1 p-1 text-black w-full pl-12 h-auto relative cursor-pointer transition">{{item.equipment}}</div>
          </div>
        </div>
      </div> -->
      <button class="button1 mt-2" style="vertical-align:middle; width:50%!important" @click="next"><span>Valider</span></button>

    </div>

  </div>
</template>

<script>
import jQuery from "jquery";
import {
  VsaList,
  VsaItem,
  VsaHeading,
  VsaContent,
  VsaIcon
} from 'vue-simple-accordion'
import 'vue-simple-accordion/dist/vue-simple-accordion.css'
const $ = jQuery

export default {
  name: "Step6.vue",
  components: {
    VsaList,
    VsaItem,
    VsaHeading,
    VsaContent,
    VsaIcon
  },
  data (){
    return {
      open: false,
    }
  },
  beforeMount() {
    this.$store.state.addedEquipement = []
    this.$store.state.equipementList = []
    if (this.$store.state.options === false){
      this.next()
    }
  },
  mounted(){
    $('.progress').animate({
        width: `60%`
      }, 200)
  },
  methods: {

    next() {
      this.$store.state.stept = 7
      if (this.$store.state.maxStept < this.$store.state.stept) this.$store.state.maxStept = 7
      this.$store.commit('getData', 'https://selling-api.openflex.eu/references/damages-sections')
      this.$store.state.loading=true
    },
    addEquipement (equiId, groupId, subId, item) {
      const div = $('.' + equiId)
      console.log(equiId)
      if (div.hasClass('validate')) {
        div.removeClass('validate')
        div.addClass('validate2')

        for (let i = 0; i < this.$store.state.equipementList.length; i++) {
          if (this.$store.state.equipementList[i].id === equiId) {
            this.$store.state.equipementList.splice(i, 1)
            break
          }
        }
        for (let y = 0; y < this.$store.state.addedEquipement.length; y++) {
          if (this.$store.state.addedEquipement[y].equipmentId === equiId) {
            this.$store.state.addedEquipement.splice(y, 1)
            break
          }
        }
      } else {
        div.addClass('validate')
        div.removeClass('validate2')
        this.$store.state.equipementList.push({ packElements: [], equipmentGroupings: [{group: 'null',  groupId: groupId, subgroup: 'null', subgroupId: subId, code: item.code }], id: equiId, wording: item.equipment, code: item.code, exclVatPrice: item.exclVatPrice, inclVatPrice: item.inclVatPrice, tax: item.tax, referencialId: equiId, serial: item.serial, pack: item.pack, custom: item.custom })
        this.$store.state.addedEquipement.push({ equipmentId: equiId, groupId: groupId, subId: subId })

      }
      console.log(this.$store.state.equipementList)
    },
    openEquipmentTab (grpName, groupe) {
/*      this.$store.state.groupe = groupe
      console.log(this.$store.state.equipment.equipmentGroups)
      $('.grp').removeClass('grpBold')
      $(`#${grpName}`).addClass('grpBold')
      this.actualize*/
      const span = $('.' + grpName + 'span')
      const div = $('.' + grpName)
      const all = $('.opening')
      if(span.hasClass('plus')){
        all.addClass('plus')
        span.removeClass('plus')
        span.addClass('min')
      } else {
        $('min').addClass('plus')
        span.removeClass('min')
        span.addClass('plus')
      }
    },
    actualize (e) {
      this.open = !this.open
      console.log(this.open)
      if(this.open === false){
        e.target.checked = false
      }
      console.log(e.target.id)
      this.$store.state.addedEquipement.forEach((item) => {
        const id = item.equipmentId
        console.log('actu id',id)
        $('.' + id).removeClass('validate2')
        $('.' + id).addClass('validate')
      })
    },
  }
}
</script>

<style scoped>
/* Tab content - closed */
.tab-content {
  max-height: 0;
  -webkit-transition: max-height .35s;
  -o-transition: max-height .35s;
  transition: max-height .35s;
}
/* :checked - resize to full height */
.tab input:checked ~ .tab-content {
  max-height: 100vh;
}
/* Label formatting when open */
.tab input:checked + label{
  /*@apply text-xl p-2 border-l-2 border-indigo-500 bg-gray-100 text-indigo*/
  font-size: .9rem; /*.text-xl*/
  padding: 1rem; /*.p-2*/
  border-left-width: 2px; /*.border-l-2*/
  border-color: #6574cd; /*.border-indigo*/
  color: rgba(36, 155, 19, 0.75); /*.bg-gray-100 */
  text-shadow: rgba(43, 192, 43, 0.4) 1px 1px 1px;
}
/* Icon */
.tab label::before {
  float:left;
  right: 0;
  top: 0;
  display: block;
  width: 1.5em;
  height: 1.5em;
  line-height: 1.5;
  font-size: 1rem;
  text-align: center;
  -webkit-transition: all .75s;
  -o-transition: all .75s;
  transition: all .75s;
}
/* Icon formatting - closed */
.tab input[type=checkbox] + label::before {
  content: "+";
  font-weight:bold; /*.font-bold*/
  border-width: 1px; /*.border*/
  border-radius: 9999px; /*.rounded-full */
  border-color: #b8c2cc; /*.border-grey*/
}
.tab input[type=radio] + label::before {
  content: "+";
  font-weight:bold; /*.font-bold*/
  border-width: 1px; /*.border*/
  border-radius: 9999px; /*.rounded-full */
  background-color: black;
  border-color: black; /*.border-grey*/
  color: white; /*.bg-gray-100 */
  text-shadow: rgba(43, 192, 43, 0.4) 1px 1px 1px;
}
/* Icon formatting - open */
.tab input[type=checkbox]:checked + label::before {
  transform: rotate(315deg);
  background-color: #ffffff; /*.bg-indigo*/
  color: #f8fafc; /*.text-grey-lightest*/
}
.tab input[type=radio]:checked + label::before {
  transform: rotateX(180deg);
  border-color: rgba(43, 192, 43, 0.4);
  background-color: rgba(43, 192, 43, 0.4); /*.bg-indigo*/
  color: white; /*.text-grey-lightest*/
}
</style>