<template>
  <div id="Step5Half" class="ml-auto mr-auto" style="width:95vw!important; height:auto;">
    <div class="flex flex-wrap">
      <div class="flexCol md:w-1/2 w-full mt-auto mb-0 mx-auto">
        <div class="flexCol md:items-center mb-2 mt-auto ">
          <div class="md:w-2/5">
            <label class="md:w-full text-gray-900 font-bold md:text-left mb-1 md:mb-0 pr-4 md:text-md text-sm"
              for="inline-full-mec">
              Date de mise en circulation
            </label>
            <div class="border border-gray-200 mb-2"></div>
          </div>
          <div class="md:w-3/5 w-full ">
            <div class="flexRow w-full">
              <select id="inline-full-mec3" v-model="selectedDay" name="inline-full-mec3"
                class=" md:text-md text-center text-sm bg-white ml-auto ml-1 appearance-none border-2 border-gray-200 rounded w-1/4 border-l-0 border-r-0 py-1 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500">
                <option value="31" selected="selected">31</option>
                <option v-for="(day, index) in days" :key="index + 'days'" :value="day">{{ day }}</option>
              </select>
              <select id="inline-full-mec" v-model="selectedMonth" name="month"
                class="md:text-md text-sm bg-white ml-1 mr-1 appearance-none border-2 text-center border-gray-200 rounded w-1/4 border-l-0 py-2 border-r-0 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500">
                <option value="01" selected="selected"> Jan </option>
                <option value="02"> Fevr </option>
                <option value="03"> Mar</option>
                <option value="04"> Avr </option>
                <option value="05"> Mai </option>
                <option value="06"> Juin</option>
                <option value="07"> Juil </option>
                <option value="08"> Aout </option>
                <option value="09"> Sept</option>
                <option value="10"> Oct</option>
                <option value="11"> Nov</option>
                <option value="12"> Dec</option>
              </select>
              <select id="inline-full-mec2" v-model="selectedYear" name="inline-full-mec2"
                class=" md:text-md text-center text-sm bg-white mr-auto ml-1 appearance-none border-2 border-gray-200 rounded w-1/4 border-l-0 border-r-0 py-1 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500">
                <option value="2022" selected="selected">2022</option>
                <option v-for="numb in year" :value="numb">{{ numb }}</option>
              </select>
            </div>
          </div>
        </div>

        <div class="flexCol w-full ">
          <div class="flexCol md:items-center mb-2">
            <div class="md:w-2/5">
              <label class="md:w-full text-gray-900 font-bold md:text-left mb-1 md:mb-0 pr-4 md:text-md text-sm"
                for="inline-full-mileage">
                Kilométrage du véhicule
              </label>
              <div class="border border-gray-200 mb-1"></div>

            </div>
            <div class="md:w-3/5 w-full ">
              <input type="number" maxlength="11" v-model="$store.state.mileage" name="mileage" placeholder=" 100000"
                class="text-center bg-white appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 ml-0 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                id="inline-full-mileage">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-wrap w-full ">
      <div class="border border-gray-200 mb-1 w-full "></div>


      <div class="flexCol md:w-1/2 sm:w-full text-left mx-auto">
        <div class="ml-1 w-full mr-auto mt-1 flexRow bg-gray-100 pl-1 rounded-xl">
          <div class="w-4/5 block text-gray-500 font-bold text-left mb-1 mt-3 md:mb-0 pr-4 md:text-sm textSm">Votre
            véhicule est-il une première main ?</div>
          <input v-model="$store.state.firstHand" class="" type="checkbox" id="firstHand">
          <label for="firstHand" class="text-black m-1 block w-1/5 text-left pl-2 text-sm" aria-describedby="label"><span
              class="ui"></span></label>
        </div>

        <!--        <label for="firstUser"  class="block text-gray-500 font-bold md:text-left  mt-3 md:mb-0 pr-4 bg-gray-100 rounded rounded-b-none">Votre véhicule est une première main ?</label>
                <div class="flexRow bg-gray-100 rounded rounded-t-none">
                  <div class="flexCol w-1/2">
                    <label class="inline-flex items-center block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="firstUseryes">
                      <input v-model="$store.state.firstHand" type="radio" class="form-radio" id="firstUseryes" name="firstUser" value="true" />
                      <span class="ml-2 text-left font-light">Oui</span>
                    </label>
                    <label class="inline-flex items-center block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="firstUserNo">
                      <input v-model="$store.state.firstHand" type="radio" class="form-radio" id="firstUserNo" name="firstUser" value="false" />
                      <span class="ml-2 text-left font-light">Non</span>
                    </label>
                  </div>

                </div>-->

        <!-- <div class="ml-1 w-full mr-auto mt-1 flexRow ">
          <div class="w-4/5 block text-gray-500 font-bold text-left mb-1 mt-3 pl-1 md:mb-0 pr-4 md:text-sm textSm">Désirez
            vous un nouveau véhicule ?</div>
          <input v-model="$store.state.trademodel.furtherInformations[0].value" class="" type="checkbox"
            id="furtherInformations">
          <label for="furtherInformations" class="text-black m-1 block w-1/5 text-left pl-2 text-sm"
            aria-describedby="label"><span class="ui"></span>
          </label>
        </div> -->

        <div class="ml-1 w-full mr-auto mt-1 flexRow bg-gray-100 pl-1 rounded-xl">
          <div class="w-4/5 block text-gray-500 font-bold text-left mb-1 mt-3 md:mb-0 pr-4 md:text-sm textSm">Votre
            véhicule est-il importé ?</div>
          <input v-model="$store.state.imported" class="" type="checkbox" id="imported">
          <label for="imported" class="text-black m-1 block w-1/5 text-left pl-2 text-sm" aria-describedby="label"><span
              class="ui"></span></label>
        </div>

        <!--  <div class="flexCol w-full">
        <div class="flexCol w-full  bg-gray-100 rounded rounded-b-none">
          <label for="newCar" class="block text-gray-500 font-bold md:text-left mb-1 mt-3 md:mb-0 pr-4 ">Votre véhicule est importé ?</label>
          <label class="inline-flex items-center block text-gray-500 font-bold md:text-right mb-1 md:mb-0" for="firstUserDontKnow">
            <input v-model="$store.state.imported" type="radio" class="form-radio" id="importYes" name="import" value="true" />
            <span class="ml-2 text-left font-light">Oui</span>
          </label>
          <label class="inline-flex items-center block text-gray-500 font-bold md:text-right mb-1 md:mb-0" for="firstUserDontKnow">
            <input v-model="$store.state.imported"  type="radio" class="form-radio" id="importNo" name="import" value="false" />
            <span class="ml-2 text-left font-light">Non</span>
          </label>
        </div>
      </div>-->
        <div class="ml-1 w-full mr-auto mt-1 flexRow">
          <div class="w-4/5 block text-gray-500 font-bold text-left mb-1 pl-1 mt-3 md:mb-0 pr-4 md:text-sm textSm">
            L'entretien est-il à jour ?</div>
          <input v-model="$store.state.upToDateMaintenanceBook" class="" type="checkbox" id="upToDateMaintenanceBook">
          <label for="upToDateMaintenanceBook" class="text-black m-1 block w-1/5 text-left pl-2 text-sm"
            aria-describedby="label"><span class="ui"></span></label>
        </div>

        <!--            <label for="newCar" class="block text-gray-500 font-bold md:text-left mb-1 mt-3 md:mb-0 pr-4">L'entretien est à jour ? </label>
                    <label class="inline-flex items-center block text-gray-500 font-bold md:text-right mb-1 md:mb-0" for="firstUserDontKnow">
                      <input v-model="$store.state.upToDateMaintenanceBook" type="radio" class="form-radio" id="entretienYes" name="entretien" value="true" />
                      <span class="ml-2 text-left font-light">Oui</span>
                    </label>
                    <label class="inline-flex items-center block text-gray-500 font-bold md:text-right mb-1 md:mb-0" for="firstUserDontKnow">
                      <input v-model="$store.state.upToDateMaintenanceBook"  type="radio" class="form-radio" id="entretienNo" name="entretien" value="false" />
                      <span class="ml-2 text-left font-light">Non</span>
                    </label>-->




        <div class="ml-1 w-full mr-auto mt-1 flexRow bg-gray-100 pl-1 rounded-xl">
          <div class="w-4/5 block text-gray-500 font-bold text-left mb-1 mt-3 md:mb-0 pr-4 md:text-sm textSm">La peinture
            est-elle en bon état ?</div>
          <input v-model="$store.state.peintureG" class="" type="checkbox" id="peintureG">
          <label for="peintureG" class="text-black m-1 block w-1/5 text-left pl-2 text-sm" aria-describedby="label"><span
              class="ui"></span></label>
        </div>

        <!--<div class="md:w-1/2 w-full flexCol">
        <div class="flexCol w-full  bg-gray-100 rounded rounded-b-none">
          <label for="newCar" class="block text-gray-500 font-bold text-left mb-1 mt-3 md:mb-0 pr-4">La peinture est en bon état ? </label>
          <label class="inline-flex items-center block text-gray-500 font-bold md:text-right mb-1 md:mb-0" for="peintureOk">
            <input v-model="$store.state.peintureG" type="radio" class="form-radio" id="peintureTrue" name="peintureOk" value="true" />
            <span class="ml-2 text-left font-light">Oui</span>
          </label>
          <label class="inline-flex items-center block text-gray-500 font-bold md:text-right mb-1 md:mb-0" for="peintureOk">
            <input v-model="$store.state.peintureG"  type="radio" class="form-radio" id="peintureFalse" name="peintureOk" value="false" />
            <span class="ml-2 text-left font-light">Non</span>
          </label>
        </div>-->

        <div class="ml-1 w-full mr-auto mt-1 flexRow">
          <div class="w-4/5 block text-gray-500 font-bold text-left pl-1 mb-1 mt-3 md:mb-0 pr-4 md:text-sm textSm">Les
            sièges sont-ils en bon état ?</div>
          <input v-model="$store.state.sellerieG" class="" type="checkbox" id="sellerieG">
          <label for="sellerieG" class="text-black m-1 block w-1/5 text-left pl-2 text-sm" aria-describedby="label"><span
              class="ui"></span></label>
        </div>
        <!-- <div class="md:w-1/2 w-full flexCol">
          <label for="newCar" class="block text-gray-500 font-bold text-left mb-1 mt-3 md:mb-0 pr-4">La sellerie en bon état ? </label>
          <label class="inline-flex items-center block text-gray-500 font-bold md:text-right mb-1 md:mb-0" for="sellerie">
            <input v-model="$store.state.sellerieG" type="radio" class="form-radio" id="sellerieTrue" name="sellerie" value="true" />
            <span class="ml-2 text-left font-light">Oui</span>
          </label>
          <label class="inline-flex items-center block text-gray-500 font-bold md:text-right mb-1 md:mb-0" for="sellerie">
            <input v-model="$store.state.sellerieG"  type="radio" class="form-radio" id="sellerieFalse" name="sellerie" value="false" />
            <span class="ml-2 text-left font-light">Non</span>
          </label>
        </div>-->
        <div class="ml-1 w-full mr-auto mt-1 flexRow bg-gray-100 pl-1 rounded-xl">
          <div class="w-4/5 block text-gray-500 font-bold text-left mb-1 mt-3 md:mb-0 pr-4 md:text-sm textSm">
            Possédez-vous un double des clés ?</div>
          <input v-model="$store.state.doubleKey" class="" type="checkbox" id="doubleKey">
          <label for="doubleKey" class="text-black m-1 block w-1/5 text-left pl-2 text-sm" aria-describedby="label"><span
              class="ui"></span></label>
        </div>
        <!--
          <label for="newCar" class="block text-gray-500 font-bold text-left mb-1 mt-3 md:mb-0 pr-4">Possedez-vous un double des clés</label>
          <label class="inline-flex items-center block text-gray-500 font-bold md:text-right mb-1 md:mb-0" for="doubleKey">
            <input v-model="$store.state.doubleKey" type="radio" class="form-radio" id="doubleKeyTrue" name="doubleKey" value="true" />
            <span class="ml-2 text-left font-light">Oui</span>
          </label>
          <label class="inline-flex items-center block text-gray-500 font-bold md:text-right mb-1 md:mb-0" for="doubleKey">
            <input v-model="$store.state.doubleKey"  type="radio" class="form-radio" id="doubleKeyFalse" name="doubleKey" value="false" />
            <span class="ml-2 text-left font-light">Non</span>
          </label>
       -->



        <div class="ml-1 w-full mr-auto mt-1 flexRow ">
          <div class="w-4/5 block text-gray-500 font-bold text-left pl-1 mb-1 mt-3 md:mb-0 pr-4 md:text-sm textSm">Le
            malus import est-il acquitté ?</div>
          <input v-model="$store.state.malusImport" class="" type="checkbox" id="test6">
          <label for="test6" class="text-black m-1 block w-1/5 text-left pl-2 text-sm" aria-describedby="label"><span
              class="ui"></span></label>
        </div>

        <div class="ml-1 w-full mr-auto mt-1 flexRow  bg-gray-100 rounded-xl">
          <div class="w-4/5 block text-gray-500 font-bold text-left pl-1 mb-1 mt-3 md:mb-0 pr-4 md:text-sm textSm">
            Avez-vous des dégâts à déclarer ?</div>
          <input v-model="$store.state.declareDmg" class="" type="checkbox" id="declareDmg">
          <label for="declareDmg" class="text-black m-1 block w-1/5 text-left pl-2 text-sm" aria-describedby="label"><span
              class="ui"></span></label>
        </div>
        <div class="ml-1 w-full mr-auto mt-1 flexRow ">
          <div class="w-4/5 block text-gray-500 font-bold text-left pl-1 mb-1 mt-3 md:mb-0 pr-4 md:text-sm textSm">
            Avez-vous ajouté des équipements au véhicule ?</div>
          <input v-model="$store.state.options" class="" type="checkbox" id="options">
          <label for="options" class="text-black m-1 block w-1/5 text-left pl-2 text-sm" aria-describedby="label"><span
              class="ui"></span></label>
        </div>
        <div class="button2 ml-auto mr-auto mt-6 w-full w-full" style=" display:block; margin-top:25px;" @click="check()">
          Valider</div>

      </div>
      <!--          <label for="newCar" class="block text-gray-500 font-bold text-left mb-1 mt-3 md:mb-0 pr-4">Le malus import est aquitté ? </label>
                <label class="inline-flex items-center block text-gray-500 font-bold md:text-right mb-1 md:mb-0" for="malusImport">
                  <input v-model="$store.state.malusImport" type="radio" class="form-radio" id="malusImportTrue" name="malusImport" value="true" />
                  <span class="ml-2 text-left font-light">Oui</span>
                </label>
                <label class="inline-flex items-center block text-gray-500 font-bold md:text-right mb-1 md:mb-0" for="malusImport">
                  <input v-model="$store.state.malusImport"  type="radio" class="form-radio" id="malusImportFalse" name="malusImport" value="false" />
                  <span class="ml-2 text-left font-light">Non</span>
                </label>-->




    </div>
  </div>
</template>

<script>
import jQuery from "jquery";
const $ = jQuery
export default {
  name: "Step5Half.vue",
  data() {
    return {
      year: [],
      days: [],
      selectedYear: '2022',
      selectedMonth: '01',
      selectedDay: '31',
      AllCompleted: false
    }
  },
  beforeMount() {
    for (let i = 2022; i > 1900; i--) {
      if (i > 1900) this.year.push(i)
    }
    for (let i = 30; i > 0; i--) {
      if (i > 0) this.days.push(i)
    }
    console.log(this.days)
  },
  mounted(){
    this.selectedDay = parseInt(this.$store.state.catalog.modelCatalogBegin.slice(8,10))
    this.selectedMonth = this.$store.state.catalog.modelCatalogBegin.slice(5,7)
    this.selectedYear = this.$store.state.catalog.modelCatalogBegin.slice(0,4)
  },
  methods: {

    next() {

      this.$store.state.stept = 6
      if (this.$store.state.maxStept < this.$store.state.stept) this.$store.state.maxStept = 6
      this.$store.commit('setEquipementsLink')
    },
    showError(title, msg) {
      this.$store.state.errorMsg = "Erreur détéctée"
      this.$store.state.errorDescription = ''

      $('#errorModalId').animate({
        top: "300px"
      }, 1000, 'linear', () => {
        this.$store.state.errorMsg = title
        this.$store.state.errorDescription = msg
      })
    },
    closeError() {
      $('#errorModalId').animate({
        top: "-600px"
      }, 1000, 'linear', () => {
        this.$store.state.errorMsg = ''
        this.$store.state.errorDescription = ''
      })
    },

    check() {

      this.$store.state.errors = [];
      const mileage = $('#inline-full-mileage')
      const mec = $('#inline-full-mec')
      const mec2 = $('#inline-full-mec2')
      const mec3 = $('#inline-full-mec3')


      if (this.$store.state.trademodel.car.putIntoService === null) {
        console.log('null')
        this.$store.state.errors.push("Veuillez renseigner l'année de mise en circulation")

        mec.addClass('error')
        mec.removeClass('valid')
        mec2.addClass('error')
        mec2.removeClass('valid')
        mec3.addClass('error')
        mec3.removeClass('valid')
      } else {
        mec.addClass('valid')
        mec.removeClass('error')
        mec2.addClass('valid')
        mec2.removeClass('error')
        mec3.addClass('valid')
        mec3.removeClass('error')
      }

      if (this.$store.state.trademodel.car.putIntoService === null) {
        console.log('null')
        this.$store.state.errors.push('Veuillez renseigner le mois de mise en circulation')
        mec2.addClass('error')
        mec2.removeClass('valid')
      } else {
        mec2.addClass('valid')
        mec2.removeClass('error')
      }
      if (!this.$store.state.mileage) {
        this.$store.state.errors.push('Veuillez renseigner le kilometrage du véhicule')
        mileage.addClass('error')
        mileage.removeClass('valid')
      } else {
        mileage.addClass('valid')
        mileage.removeClass('error')
      }
      console.log(this.selectedDay, this.selectedMonth, this.selectedYear)
      if (this.selectedDay === "31" && this.selectedMonth === "01" && this.selectedYear === "2022") {
        this.$store.state.errors.push('Veuillez renseigner la date de mise en circulation')
        mileage.addClass('error')
        mileage.removeClass('valid')
      } else {
        mileage.addClass('valid')
        mileage.removeClass('error')
      }

      if (this.$store.state.errors.length < 1) {
        console.log(this.$store.state.trademodel.car.putIntoService)
        this.$store.state.trademodel.car.putIntoService = ''
        this.$store.state.trademodel.car.putIntoService = this.selectedYear + '-' + this.selectedMonth + '-' + this.selectedDay
        this.next()
      } else {
        let string = ''
        this.$store.state.errors.forEach((e) => {
          string += e + ',' + ' '
        })
        this.showError('Champs manquants', string)
      }
    }
  }
}
</script>

<style scoped></style>