<template>
  <div id="StepHalf" class="w-full mt-1 mb-1 rounded" style="height:90%;">
    <div class="w-full text-black border-b border-gray-300 font-bold pb-2 mb-5 textSm md:text-md"> Quelle est la version de votre véhicule ? </div>


    <div :id="model.referentialCarId" v-for="(model, index) in $store.state.manualSearch" :key="index" class="rounded w-full pl-2 cursor-pointer  pr-2" @click="getCatalog(model.id, 2, model.catalog, model )">
      <div class="text-left w-full pl-2  flexRow textMd hover:border-red-500 border-2 mb-2 rounded transition" style="height:auto;">
        <div class="md:w-3/5 w-full" style="height:auto;">{{model.id}} {{ model.version }} {{ model.trimline}}</div>
        <div class="flexCol w-1/6">
          <div class="door ml-auto mr-auto"></div>
          <div style="" class="font-bold mr-2 textSm text-center"> {{ model.doors }}</div>
        </div>
        <div class="flexCol w-1/6 ">
          <svg width="20px" viewBox="0 0 17.4 17.4" class="ml-auto mr-auto mt-auto sc-iBzEeX glZmJR sc-dTSzeu krkYvd"><path d="M6.5 8H5.1v1.5h1.4V8zm2.9 0H8v1.5h1.4V8zm2.9 0h-1.4v1.5h1.4V8zm1.5-5.1H13V1.4h-1.5v1.4H5.8V1.4H4.3v1.4h-.7c-.8 0-1.4.6-1.4 1.4v10.1c0 .8.6 1.4 1.4 1.4h10.1c.8 0 1.4-.6 1.4-1.4v-10c.1-.8-.5-1.4-1.3-1.4zm0 11.6H3.6v-8h10.1l.1 8z"></path></svg>
          <div class="text-red-700 font-bold  mt-auto ml-auto mr-auto flexRow pb-1 textSm text-center" style=" ">{{ model.catalog }} </div>
        </div>
        <div class="arrowRedRight  ml-auto mr-1 pr-1 w-4"></div>

      </div>
    </div>

<!--    <div  :id="model.referentialCarId" v-for="(model, index) in $store.state.manualSearch"
          :key="index" @click="getCatalog(model.referentialCarId, 2, model.catalog, model)"
          class=" prPoint border cursor-pointer text-black hover:bg-white hover:text-black transition border-white flexCol text-sm rounded-sm w-full m-2"
          style="margin:auto;"
    >
      <div class="border-b border-gray-300" style="width:60vw;height:6px;background-color: black; border-top-left-radius: 5px;border-top-right-radius:5px;"></div>
      <div class="flexRow mr-3  border border-gray-300 p-2" style="width:60vw">
        <div class="bg-white ml-2 mr-3 border-black border h-5 w-5 z-10 relative" style="border-radius:50%;">
          <div  class="point h-3 hover:bg-black w-3 border-white absolute border-3 bg-white z-20" style="border-radius:50%;top:3px;left:3px;"></div>
        </div >{{ $store.state.selectedMarqueArray.make }} {{ model.version }}  ( {{ model.doors }} p )   </div>
    </div>-->
  </div>

</template>

<script>
export default {
  name: "StepHalf",
  methods: {
    getCatalog(id, step, catalog, model) {
      this.$store.state.loading = true
      this.$store.state.modelChoose = model
      this.$store.state.referentialCarId = id
      this.$store.state.stept = step
      this.$store.state.sharePoint.step = step
      if (this.$store.state.maxStept < this.$store.state.stept) this.$store.state.maxStept = step

      this.$store.commit('getCatalog')
    }
  }
}
</script>

<style scoped>

</style>